import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './allServices/api.service';
import { RouterOutlet } from '@angular/router';
import { fader, slider } from './route-animations';

const EXPIRE_TIME = 20000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ fader,  ]
})
export class AppComponent {
  @HostListener("dragend")ondragend(){
    window.location.reload();
    console.log("clicked")
  }
  title = 'PMEL';
  URI:any='https://jsonip.com';
  ipAddress:any;
  public lat;
  public lng;

  constructor(private ApiService: ApiService, @Inject(Router)  private router: Router,){
  }
  ngOnInit(){
    // this.loggedInData = localStorage.getItem('user_data');
    // this.loggedInRolId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.getIPOrLocation();
    sessionStorage.setItem('test', '1');
  }
  ngDoCheck() {
    // for(let i=0; i<(i+1); i++){}
    // setTimeout(function() {
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("user_data");
    //   localStorage.removeItem("farmer_profile_id");
    //   location.reload();
    //   console.log("after an 2 min it will delete the data")
    // }, EXPIRE_TIME); // after an hour it will delete the data
  }

  getIPOrLocation(){
    // this.ApiService.getIPAddress(this.URI)
    // .subscribe( data => {
    //   console.log('th data', data);
    //   this.ipAddress = data
    // })
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     if (position) {
    //       console.log("Latitude: " + position.coords.latitude +
    //         "Longitude: " + position.coords.longitude);
    //       this.lat = position.coords.latitude;
    //       this.lng = position.coords.longitude;
    //     }
    //   },
    //     (error) => console.log(error));
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
  }
 
}
