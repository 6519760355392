import { Component, OnInit } from '@angular/core';
import { field, value } from '../../global.model';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from '../../allServices/api.service';
import {ToastrService} from 'ngx-toastr';
import { UriList } from '../../uri.list';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-kpi-questions',
  templateUrl: './kpi-questions.component.html',
  styleUrls: ['./kpi-questions.component.css']
})
export class KpiQuestionsComponent implements OnInit {
  kpis:any = [];
  itemButton:string="";
  questionData:any = [];
  kpi_id:any=[];
  editMode:'';
  stackholderListData:[];
  kpiOrStakeholderView: number=1;
  getAllKpiQuestionsId: any;
  getStakeholderQuestionsId: any;
  loggedInData: string;
  loggedInUserId:string;
  loggedInRoleId:number;
  loggedInRecId:number;
  constructor(private ApiService: ApiService,private toastr:ToastrService) { }
  value:value={
    label:"",
    value:""
  };
  success = false;

  modelFields:Array<field>=[];
  model:any = {
    name:'',
    description: '',
    kpiId: 0,
    Stakerholder:'',
    theme:{
      bgColor:"ffffff",
      textColor:"000000",
      bannerImage:""
    },
    attributes:this.modelFields
  };

  modelData:Array<field>=[];
  modelDat:any = {
    kpi_id:this.modelData
  };

  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserId = (JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).user_id: "0";
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0);
    this.loggedInRecId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).region_id : 0)
    // this.getAllKpiQuestions(4);
    this.getStackholderData();
    
  }
  ngAfterViewInit(): void{
    this.loadAllKpis();
  }
  ngDoCheck() {
    let dropId = this.model.name;
    this.model.kpiId = dropId.substring(dropId.indexOf('$$')+2);
    if(Object.keys(this.model.attributes).length > 0){
      this.itemButton = "button";
      // console.log(this.itemButton)
    }
  }
  toggleValue(item){
    item.selected = !item.selected;
  }

  submit(){
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.model.attributes));
    validationArray.reverse().forEach(field => {
      // console.log(field.label+'=>'+field.required+"=>"+field.value);
      if(field.required && !field.value && field.type != 'checkbox'){
        new swal('Error','Please enter '+field.label,'error');
        valid = false;
        return false;
      }
      if(field.required && field.regex){
        let regex = new RegExp(field.regex);
        if(regex.test(field.value) == false){
          new swal('Error',field.errorText,'error');
          valid = false;
          return false;
        }
      }
      if(field.required && field.type == 'checkbox'){
        if(field.values.filter(r=>r.selected).length == 0){
          new swal('Error','Please enterrr '+field.label,'error');
          valid = false;
          return false;
        }

      }
    });
    if(!valid){
      return false;
    }
    console.log('Save',this.model);
    let input = new FormData;
    input.append('formId',this.model._id);
    input.append('attributes',JSON.stringify(this.model.attributes))
    // console.log(input);
    this.ApiService.create(this.model,`${UriList.QUESTION}`).subscribe(r=>{
      // console.log(r);
      new swal('Success','Thank you!','success');
      this.success = true;
    },error=>{
      new swal('Error',error.message,'error');
    });
  }
  private loadAllKpis() {
    // let params
    // if(0){
      // params = new HttpParams().set("user_id",this.loggedInUserId);
    // }
  
    this.ApiService.getById(this.loggedInRecId,`${UriList.GETKPISCREATEDBYREC}`).pipe(first()).subscribe(kpis => {
      this.kpis=kpis['data'];
        // console.log(kpis['data']);
    });
  }
  public getAllKpiQuestions(id:number) {
    // console.log(id)
    this.getAllKpiQuestionsId = id;
    this.reset();
    this.ApiService.getById(id, `${UriList.GETKPIFORM}`).pipe(first()).subscribe(questionData => {
      // console.log(questionData['data'])
      if(Object.keys(questionData['data']).length > 0){
        this.itemButton = "button";
      }else{
        this.itemButton = "";
        new swal("", "No data found.", "warning");
      }
      // console.log(questionData['data'])
      this.questionData=questionData['data'];
    });
  }
  public getAllStackholderData(id:number) {
    this.getStakeholderQuestionsId = id;
    this.reset();
    this.ApiService.getById(id, `${UriList.GETSTAKEHOLDERFORM}`).pipe(first()).subscribe(questionData => {
      // console.log(questionData['data'])
      if(Object.keys(questionData['data']).length > 0){
        this.itemButton = "button";
      }else{
        this.itemButton = "";
        new swal("", "No data found.", "warning");
      }
      console.log(questionData['data'])
      this.questionData=questionData['data'];
    });
  }
  reset(){
    this.model.attributes=[];
  }
  editKpiQuestions(question_id)
  {
    this.editMode='';
    // console.log(question_id);
    this.editMode=question_id;
  }
  saveQestions(data)
  {
   // console.log(data[0])
    this.editMode='';
    this.ApiService.put(data[0],`${UriList.QUESTION}/${data[0].kpi_question_id}`).pipe(first()).subscribe(questionData => {
      // console.log(questionData['data'])
      if(Object.keys(questionData['data']).length > 0){
        this.editMode='';
        (data[0].stakeholder_id) ? this.getAllStackholderData(data[0].stakeholder_id) : this.getAllKpiQuestions(this.getAllKpiQuestionsId);
      }else{
        this.itemButton = "";
        new swal("", "Sorry we can't update.", "warning");
      }
    },error=>{
      console.log(error)
      new swal('Error',error.message,'error');
    });
  }
  deleteQuestion(id)
  {
    new swal({
      title: 'Are you sure?',
      text: "Do you want to remove?",
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if(result.value){
        return this.ApiService.delete(`${UriList.QUESTION}/${id}`).pipe(first()).subscribe(r =>{
          this.toastr.success('Deleted Successfully', 'Success!', {
            positionClass: 'toast-bottom-right',
            timeOut: 5000
          });
          this.getAllKpiQuestions(this.getAllKpiQuestionsId)
        },error=>{
          // console.log(error.error.errors)
          new swal('Error',error.error.errors,'error');
        })
      }
    })
  }
  async getStackholderData()
  {
    let URI =`${UriList.GETSTAKEHOLDER}`;
    return await this.ApiService.get(URI).pipe(first()).subscribe(val => {
      this.stackholderListData=val['data'];
      // console.log(val['data'][0])
    });
  }
  public kpiOrStakerholderAction()
  {
    this.model.kpi_id=0;
    this.model.stakeholder_id=0;
    this.questionData=[];
    this.getAllKpiQuestionsId=0;
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}
