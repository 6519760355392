import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KpiQuestionsComponent } from './pages/kpi-questions/kpi-questions.component';
import { CreateKpiComponent } from './pages/create-kpi/create-kpi.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { AdminAuthGuard } from './authGuard/admin-auth.guard';
import { RecLevelGuard } from './authGuard/rec-level.guard';
import {GeneralGuard} from './authGuard/general.guard'
import { CreateQuestionsComponent } from './pages/create-questions/create-questions.component';
import { LoginComponent } from './authentication/login/login.component';
import { HomeComponent } from './home/home.component';
import { DataCollectorDashboardComponent } from './pages/data-collector-dashboard/data-collector-dashboard.component';
import { CreateFormComponent } from './pages/create-form/create-form.component';
import { ProjectManagerGuard } from './authGuard/project-manager.guard';
import { DataCollectorComponent } from './pages/data-collector/data-collector.component';
import { DataCollectorGuard } from './authGuard/data-collector.guard';
import { DataCollectionComponent } from './pages/data-collection/data-collection.component';
import { GetAnswerComponent } from './pages/get-answer/get-answer.component';
import { KpiListComponent } from './pages/kpi-list/kpi-list.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import {ProjectManagersListComponent} from './pages/project-managers-list/project-managers-list.component';
import { ExportDataComponent } from './pages/export-data/export-data.component';
import { GetDataCollectionComponent } from './pages/get-data-collection/get-data-collection.component';
import { FormBasedQuestionsComponent } from './pages/form-based-questions/form-based-questions.component';
import { FormsListComponent } from './pages/forms-list/forms-list.component';
import {PasswordResetComponent} from './authentication/password-reset/password-reset.component'

const routes: Routes = [
  { 
    path: '', 
    component: HomeComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'} 
  },
  { 
    path: 'login', 
    component: LoginComponent,
    // data:{ animation: 'isLeft'} 
  },
  { 
    path: 'create-questions', 
    "canActivate": [GeneralGuard],
    component: CreateQuestionsComponent,
    data:{ animation: 'isLeft'} 
  },
  {
    path:'question-list', 
    component: KpiQuestionsComponent, 
    canActivate: [AdminAuthGuard],
    data:{ animation: 'isLeft'} 
  },
  {
    path:'create-kpi', 
    component: CreateKpiComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'create-project', 
    component:CreateProjectComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'create-form', 
    component:CreateFormComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'data-collector', 
    component:DataCollectorComponent,
    canActivate: [ProjectManagerGuard],
    data: { animation: 'isLeft'}
  },
  {
    path:'data-collection', 
    component:DataCollectionComponent,
    canActivate: [DataCollectorGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'get-answers', 
    component:GetAnswerComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'kpi-list', 
    component:KpiListComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'kpi-questions', 
    component:KpiQuestionsComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'export-data', 
    component:ExportDataComponent,
    canActivate: [GeneralGuard],
    data:{ animation: 'isLeft'}
  },
  {
    path:'quiz-test', 
    component:QuizComponent,
    canActivate: [AdminAuthGuard],
    children:[
      {
        path: 'quizs',
        component: ExportDataComponent,
        canActivate: [GeneralGuard],
        data:{ animation: 'isLeft'}
      }
    ]
  },  
  {
    path: 'manager-list',
    component: ProjectManagersListComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path:'get-data-collection', 
    component:GetDataCollectionComponent,
    canActivate: [DataCollectorGuard]
  },
  {
    path:'form-based-questions/:id/:project_form_id/:form_status/:stakeholder_id', 
    component:FormBasedQuestionsComponent,
    canActivate: [GeneralGuard]
  },
  {
    path:'forms-list', 
    component:FormsListComponent,
    canActivate: [GeneralGuard]
  },
  {
    path:'collector-dashboard', 
    component:DataCollectorDashboardComponent,
    canActivate: [DataCollectorGuard]
  },
  {
    path:'password-reset', 
    component:PasswordResetComponent,
    canActivate: [GeneralGuard]
  },
  { 
    path: '**', redirectTo: '' 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AdminAuthGuard,ProjectManagerGuard,DataCollectorGuard]
})
export class AppRoutingModule { }
