import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { ToastrService } from 'ngx-toastr';
import{ ApiService } from '../../allServices/api.service';
import { field } from 'src/app/global.model';
import { first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { UriList } from '../../uri.list';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  questions:any;
  currentIndex:number;
  notAttempted:any;
  correct:any;
  currentQuestionSet:any;
  success = false;
  itemButton:string="";
  questionData:any = [];
  projectsData: any;
  loggedInData: string;
  formdata;
  sortDir = 1;//1= 'ASE' -1= DSC
  users =[{
    fname:'Jacob',lname:	'Otto',	'handle':'@mdo'
  },{
    fname:'Mark',lname:	'Thornton',	'handle':'@fat'
  },{
    fname:'XYZ',lname:	'ABC',	'handle':'@Demo'
  },{
    fname:'Larry',lname:	'the Bird',	'handle':'@twitter'
  }];


  constructor(private toastr:ToastrService, 
    private ApiService: ApiService,
    ){
 
    //Creating summy questions Json dta
    this.questions=[]
 
    this.currentIndex=0;
    this.currentQuestionSet= this.questions[this.currentIndex];
    this.getProjectData()
    this.loggedInData = localStorage.getItem('user_data');
    this.ansModel.data_collector_id =(this.loggedInData)? JSON.parse(this.loggedInData).user_id : 0;
  }
  ngOnInit(): void {
    this.formdata = new FormGroup({
      emailid: new FormControl("angular@gmail.com"),
      passwd: new FormControl("abcd1234")
   });

  }
  modelFields:Array<field>=[];
  model:any = {
    attributes:this.modelFields
  };
  ansModel:any={
    project_id: '',
    question_id: '',
    data_collector_id: '',
    farmer_profile_id: 1,
    form_id: '',
    name: '',
    value: ''
  }
  toggleValue(item){
    item.selected = !item.selected;
  }
  
  onClickSubmit(data) {
    alert("Entered Email id : " + data.emailid);
  }

  
  getQuestionStoreAnswer(){
    this.questionData=[];
    this.currentIndex = this.currentIndex + 1;
    this.currentQuestionSet= this.questions[this.currentIndex];
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.model.attributes));
    validationArray.reverse().forEach(field => {
      // console.log(field.label+'=>'+field.required+"=>"+field.value);
      if(field.required && !field.value && field.type != 'checkbox'){
        new swal('Error',''+field.label,'error');
        valid = false;
        return false;
      }
      if(field.required && field.regex){
        let regex = new RegExp(field.regex);
        if(regex.test(field.value) == false){
          new swal('Error',field.errorText,'error');
          valid = false;
          return false;
        }
      }
      if(field.required && field.type == 'checkbox'){
        if(field.values.filter(r=>r.selected).length == 0){
          new swal('Error',' '+field.label,'error');
          valid = false;
          return false;
        }
      }
    
    });
    if(!valid){
      return false;
    }
    let URI =`${UriList.GETQUESTIONSTOREANSWER}`;
    return this.ApiService.create(this.ansModel,URI).pipe(first()).subscribe(r => {
      this.ansModel.question_id=r['data'][0]['question_id'];
      this.ansModel.form_id=r['data'][0]['form_id'];
      this.ansModel.name=r['data'][0]['name'];
      // console.log(r['data'])
      if(Object.keys(r['data']).length == 0){
        new swal("success", "No data found.", "warning");
      }else{
        this.toastr.success('successfully answered', 'Success!');
        this.questionData=r['data'];
      }
      
    });
  }
  previousQuestion()
  {
    this.currentIndex = this.currentIndex - 1;
    this.currentQuestionSet= this.questions[this.currentIndex];
  }
  submit(){
    this.toastr.success('Hello world!', 'Success!');
    this.toastr.warning('Warning!', 'Warning!');
    this.toastr.error('everything is broken', 'Major Error', {
      timeOut: 500
    });
    // new swal('Success','You have created sucessfully','success');
  }
  private getProjectData()
  {
    let dataCollectorId = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    let URI =`${UriList.DATACOLLECTORPROJECT}/${dataCollectorId}`;
    return this.ApiService.get(URI).pipe(first()).subscribe(r => {
      this.projectsData=r['data'];
    });
  }


  getFormByProjectId(id:any)
  {
    this.reset();
    this.model.project_id=id;
    let params = new HttpParams().set("project_id",id)
    this.ApiService.getByParams(`${UriList.GETQUESTIONSTOREANSWER}`,{params:params}).pipe(first()).subscribe(questionData => {
      if(Object.keys(questionData['data']).length == 0){
        new swal("", "No data found.", "warning");
      }
      this.ansModel.project_id=id;
      this.ansModel.question_id=questionData['data'][0]['question_id'];
      this.ansModel.form_id=questionData['data'][0]['form_id'];
      this.ansModel.name=questionData['data'][0]['name'];
      // console.log(this.ansModel)
      this.questionData=questionData['data'];
    });
  }
  reset(){
    this.questionData=[];
    this.model.attributes=[];
  }

  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir=-1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir=1;
    }
    this.sortArr('fname');
  }
  sortArr(colName:any){
    this.users.sort((a,b)=>{
      a= a[colName].toLowerCase();
      b= b[colName].toLowerCase();
      return a.toString().localeCompare(b.toString()) * this.sortDir;
    });
  }
}

