import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UriList } from 'src/app/uri.list';
import { ApiService } from '../../allServices/api.service'

@Component({
  selector: 'app-data-collector-dashboard',
  templateUrl: './data-collector-dashboard.component.html',
  styleUrls: ['./data-collector-dashboard.component.css']
})
export class DataCollectorDashboardComponent implements OnInit {

  public loggedInUserName:string;
  public loggedInData: any;
  public loggedInRoleId:string;
  
  constructor(private ApiService:ApiService) { }
  
  dashboardData:any = {
    target_assigned:'',
    target_achieved:'',
    ongoing_collection:'',
    collection_completed:'',
  }
  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.getDashboardData();
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  async getDashboardData()
  {
    let params = JSON.parse(this.loggedInData);
    return await this.ApiService.getByParams(`${UriList.DASHBOARD}/${JSON.parse(this.loggedInData).user_id}`,params)
    .pipe(first()).subscribe(r => {
      // this.dashboardData=r['data'];
      // console.log(r['data'])
      this.dashboardData.target_assigned=r['data'].target_assigned;
      this.dashboardData.target_achieved=r['data'].target_achieved;
      this.dashboardData.ongoing_collection=r['data'].ongoing_collection;
      this.dashboardData.collection_completed=r['data'].collection_completed;
    });
  }
}
