import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from '../../allServices/api.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UriList } from '../../uri.list';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-create-kpi',
  templateUrl: './create-kpi.component.html',
  styleUrls: ['./create-kpi.component.css']
})

export class CreateKpiComponent implements OnInit {

  dropdownList = [];
  dropdownProjectList=[];
  outcomeIndicatorObj = [];
  outcomeProjectIndicatorObj = [];
  dropdownSettings:IDropdownSettings={};
  dropdownProjectSettings:IDropdownSettings={};
  loggedInData:any;
  projectsData:any=[];
  constructor(private ApiService:ApiService, private router: Router,) { }
  ngOnInit() {

    this.dropdownList = [];
    this.dropdownProjectList = [];
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true
    };
    this.dropdownProjectSettings = {
      idField: 'id',
      textField: 'project_details',
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true
    };
  }
  ngAfterViewInit(): void{
    this.relatedOutcomeIndicator();
    this.getProjectsData();
  }
  ngDoCheck() {
    this.model.related_outcome_indicator_id = this.outcomeIndicatorObj.map(s=>s.id);
    this.model.project_codes = this.outcomeProjectIndicatorObj.map(project=>project.id);
    this.loggedInData = localStorage.getItem('user_data');
    this.model.user_id = JSON.parse(this.loggedInData).user_id;
    this.model.region_id = JSON.parse(this.loggedInData).region_id;
    // console.log(this.model);
  }
  model:any = {
    user_id: "",
    related_outcome_indicator_id : [],
    project_codes:[],
    title : "",
    rational : "",
    definition : "",
    intervation_area_id:1,
    outcome_statement_id:1,
    kpi_number:"",
    indicator_code:"",
    stakeholder_id:1,
    kpi_level_id:2,
    region_id:"",
  }
  reset(){
    this.outcomeIndicatorObj=[];
    this.model.title="";
    this.model.kpi_number="";
    this.model.indicator_code="";
  }
  relatedOutcomeIndicator()
  {
    const URI=`${UriList.GETRELATEDPUTCOMEINDICATOR}`;
    this.ApiService.get(URI).pipe(first()).subscribe(outcomeIndicator => {
      // console.log(outcomeIndicator['data'])
      this.dropdownList = outcomeIndicator['data'];
    });
  }
  submit(){
    // console.log(this.model);
    // return false;
    if(this.validateFields() == false){
      this.validateFields();
      return false;
    }
    this.ApiService.create(this.model,'kpi').subscribe(r=>{
      // console.log(r);
      this.outcomeIndicatorObj=[];
      this.model.title="";
      new swal('Success','created successfully','success');
      this.router.navigate(['/home']);
    },error=>{
      new swal('Error',error.message,'error');
    });
  }
  async getProjectsData()
  {
    let URI =`${UriList.GETPROJECT}`;
    let params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id)
    // console.log(params)
    return await this.ApiService.getByParams(URI,{params:params}).pipe(first()).subscribe(r => {
      this.dropdownProjectList=r['data'];
      // console.log(r['data'])
    });
  }
  private validateFields()
  {
    if(this.model.title == "")
    {
      new swal('Error','Please enter valid title','error');
      return false;
    }
    if(this.model.kpi_number == "")
    {
      new swal('Error','Please enter valid kpi number','error');
      return false;
    }
    if(this.model.indicator_code == "")
    {
      new swal('Error','Please enter valid indicator code','error');
      return false;
    }
  }
}
