import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { trigger, state, style, animate, transition, query, stagger  } from '@angular/animations';

declare var anime: any;          

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [ trigger('simple',[
    transition(':enter',
      animate('4000s',
        style({transform: 'translateX(600%)', opacity: '200'})
      ),
    )
  ]),
  trigger('scroll', [
    state('on', style({left: '-100px'})),
    transition('* => *', [
      style({left: '-100px'}),
      animate(10000, style({left: '100%'}))
    ])
  ]),
  trigger('myTrigger', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition('void => *', [animate('0.1s 0.1s ease-in')]),
    transition('* => void', [animate('0.1s ease-in')])
  ]),
  trigger("fadeInOut", [
    state(
      "void",
      style({
        opacity: 0
      })
    ),
    transition("void <=> *", animate(1000))
  ]),

  trigger("EnterLeave", [
    state("flyIn", style({ transform: "translateX(0)" })),
    transition(":enter", [
      style({ transform: "translateX(-100%)" }),
      animate("0.5s 300ms ease-in")
    ]),
    transition(":leave", [
      animate("0.1s ease-out", style({ transform: "translateX(100%)" }))
    ])
  ]),
  trigger('stagger', [
    transition('* => *', [ 
      query(':enter', [
          style({ opacity: 0 }),
          stagger(1000, [animate('0.5s', style({ opacity: 1 }))])
        ], { optional: true }
      )
    ])
  ])
 ]
})
export class HeaderComponent implements OnInit , AfterViewInit{
  private history:string[] = [];
  loggedInData: string;
  loggedInUserName:string;
  loggedInRoleId:number;
  isActive: true;
  mainBody:string='col-11 mr-2'
  stateToggle:boolean;
  isShown: boolean = false ; // hidden by default
  isCollapsed:boolean;
  public clickCount: boolean =  true;
  show: boolean = true;
  state=0;
  constructor(private router: Router, private location: Location,private toastr:ToastrService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }
  ngOnInit(){
    setInterval(()=> {
      this.show = !this.show;
    } ,1500);
  }
  ngAfterViewInit(): void {
    // Wrap every letter in a span
    // const textWrapper = document.querySelector('.an-1');
    // textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    // anime.timeline({loop: true})
    // .add({
    //   targets: '.an-1 .letter',
    //   scale: [4,1],
    //   opacity: [0,1],
    //   translateZ: 0,
    //   easing: "easeOutExpo",
    //   duration: 950,
    //   delay: (el, i) => 70*i
    // }).add({
    //   targets: '.an-1',
    //   opacity: 0,
    //   duration: 1000,
    //   easing: "easeOutExpo",
    //   delay: 1000
    // });

  }
  onClickNavbarChange(e: any) {
    if(this.loggedInRoleId != 0){
        let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
        if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
            element.click();
        }
      }   
  }
  scrollDone() {
    this.state++;
  }
  ngDoCheck() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0);
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("farmer_profile_id");
    this.toastr.warning('Logged Out Successfully', 'Success!', {
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
    });
    this.router.navigate(['login']);
    // this.router.navigate(['/home']);
  }
  sidebarCollapse(){
    this.stateToggle=!this.stateToggle;
    this.isShown = ! this.isShown;
    this.mainBody= (this.stateToggle) ? "col-12": "col-11  mr-2";
  }
  

  
}
