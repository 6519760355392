<div class="container dataCollector" style="background-color: aliceblue;">
  <!-- <router-outlet></router-outlet> -->
  <a class="nav-link" [routerLink]="['/quiz-test','quizs']">child</a>
  <!-- <div>
    <form [formGroup] = "formdata" (ngSubmit) = "onClickSubmit(formdata.value)">
       <input type = "text" class = "fortextbox" name = "emailid" placeholder = "emailid" 
          formControlName="emailid">
       <br/>
       
       <input type = "password" class = "fortextbox" name="passwd" 
          placeholder = "passwd" formControlName = "passwd">
       <br/>
       
       <input type = "submit" class = "forsubmit" value = "Log In">
    </form>
 </div> -->

  <h4>Data Collection</h4>
    <div class="col-6 offset-3 mb-4">
      <label>Select Project <i style="color: red;">*</i></label>
      <h5><select class="form-control" (change)="getFormByProjectId($event.target.value)">
        <option value="" selected disabled>Select project</option>
        <option *ngFor="let x of projectsData" value="{{x.id}}" >{{x.name}}</option>
      </select> </h5>
    </div>

<div class="container mt-4 mb-5">
  <div class="col-10 offset-1 mt-4 mbHeight">
    <form class="ftheme" >
    
      <div *ngIf="!success" class="col-12">
       
        <div *ngFor="let item of questionData" class="form-group">
          <div *ngIf="item.type=='text'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [required]="item.required" [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
          <div *ngIf="item.type=='email'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
          <div *ngIf="item.type=='phone'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
          <div *ngIf="item.type=='number'">
              <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
              <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}" max="{{item.max}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
            </div>
          <div *ngIf="item.type=='date'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="date" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
          <div *ngIf="item.type=='datetime-local'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="datetime-local" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
          <div *ngIf="item.type=='textarea'">
              <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
              <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
              </textarea>
            </div>
          <div *ngIf="item.type=='file'">
            <label>{{item.label}} (File can't be uploaded right now)</label> <label *ngIf="item.required" class="text-danger">*</label>
            <input type="file" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <small class="form-text text-danger">(File can't be uploaded right now)</small>
          </div>
          <div *ngIf="item.type=='paragraph'">
            <p class="form-text">{{item.placeholder}}</p>
          </div>
          <div *ngIf="item.type=='autocomplete'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <select class="form-control" id="{{item.name}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>  
            </select> -->
            <!-- <small class="form-text text-muted">{{item.description}}</small> -->
           </div>
          <div *ngIf="item.type=='checkbox'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <div *ngFor="let v of item.values" class="inline-form-group">
                <label class="cust-check-bx">
                  <input type="checkbox" [value]="v.value" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
                  <span class="checkmark"></span>
                </label>
              </div>
          </div>
          <div *ngIf="item.type=='radio'">
              <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
              <div *ngFor="let v of item.values" class="inline-form-group">
                  <label class="cust-check-bx">
                    <input type="radio" [value]="v.value" name="{{item.name}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}"> {{v.label}}
                    <span class="checkmark"></span>
                  </label>
                </div>
            </div>
        </div>

      </div>
      </form>
    </div>
    <h5 class="nextPrevious">{{currentIndex+1}}/{{questions.length}}</h5><hr />
    <button *ngIf="currentIndex > 0" class="btn btn-outline-primary previous" value="Previous" (click)="previousQuestion()"> <i class="fa fa-backward" aria-hidden="true"></i> </button>
    <button *ngIf="(currentIndex+1) != questions.length" class="btn btn-outline-primary pull-right" value="Next" (click)="getQuestionStoreAnswer()"> <i class="fa fa-forward" aria-hidden="true"></i> </button>
</div>
<router-outlet></router-outlet>


<table class="mytable">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First<i (click)="onSortClick($event)" class="my-icon fa fa-chevron-up"></i></th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let obj of users; let i=index;">
      <th scope="row">{{i}}</th>
      <td>{{obj.fname}}</td>
      <td>{{obj.lname}}</td>
      <td>{{obj.handle}}</td>
    </tr>
  </tbody>
</table>

