import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../allServices/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {}
    private token: string;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('token');
        // console.log("token ->" + token);
        const authReq = req.clone({
            headers: new HttpHeaders({
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer' + token
            })
        });
        return next.handle(authReq);
    }
}