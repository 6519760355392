import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import {ToastrService} from 'ngx-toastr';
import { UriList } from '../../uri.list';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-based-questions',
  templateUrl: './form-based-questions.component.html',
  styleUrls: ['./form-based-questions.component.css']
})
export class FormBasedQuestionsComponent implements OnInit {

  success = false;
  public id: string;
  projectsData:any;
  stakeholderData:any;
  public project_form_id:string;
  public stakeholder_id:string;
  public showTextBox:boolean=false;
  public remarks:string;
  public form_status:string;
  loggedInData: string;
  loggedInRoleId:string;
  constructor(private ApiService:ApiService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.id = this.route.snapshot.paramMap.get('id');
    this.project_form_id = this.route.snapshot.paramMap.get('project_form_id');
    this.stakeholder_id = this.route.snapshot.paramMap.get('stakeholder_id');
    this.form_status = this.route.snapshot.paramMap.get('form_status');
    this.getProjectData();
    this.getStakeholderData();
    console.log(this.stakeholder_id)
  }

  private async getProjectData()
  {
    let URI =`${UriList.GETFORMBYPROJECTID}`;
    await this.ApiService.get(`${URI}/${this.id}`).pipe(first()).subscribe(r => {
      this.projectsData=r['data'];
      this.success=true;
      // console.log(r['data'])
    });
  }
  
  private async getStakeholderData()
  {
    let URI =`${UriList.GETSTAKEHOLDERFORM}`;
    await this.ApiService.get(`${URI}/${this.stakeholder_id}`).pipe(first()).subscribe(res => {
      this.stakeholderData=res['data'];
      this.success=true;
      // console.log(res['data'])
    });
  }
  approved(status){
    let URI = `${UriList.STOREFORMSTATUS}`;
    return this.ApiService.create({"project_form_id":this.project_form_id,"status":status},URI).pipe(first()).subscribe(r => {
      new swal('Success','You have contact sucessfully','success');
      this.router.navigate(['/export-data']);
    },error=>{
      new swal('Error',error.message,'error');
    });
  }
  showRejected(){
    this.showTextBox=true;
  }
  
  rejected(status){
    this.showTextBox=true;
    let URI = `${UriList.STOREFORMSTATUS}`;
    console.log({"project_form_id":this.project_form_id,"status":status,"remarks":this.remarks})
    return this.ApiService.create({"project_form_id":this.project_form_id,"status":status,"remarks":this.remarks},URI).pipe(first()).subscribe(r => {
      new swal('Success','You have contact sucessfully','success');
      this.remarks='';
      this.showTextBox=false;
      this.router.navigate(['/export-data']);
    },error=>{
      new swal('Error',error.message,'error');
    });
  }
  
}
