import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import {ToastrService} from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
// import * as XLSX from 'xlsx';
import { UriList } from '../../uri.list';

@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.css']
})
export class FormsListComponent implements OnInit {

 
  projectsFormData:any;
  filterTerm:any;
  p: number = 1;
  loggedInData: string;
  loggedInRoleId:string;
  dataCollector:any;
  sortDir = 1;//1= 'ASE' -1= DSC
  constructor(private ApiService:ApiService,private toastr:ToastrService) { }
  projectData:any={
    data_collector_id: '',
    project_id:'',
    manager_id:'',
    target: '',
    project_form_id:''
  }
  ngOnInit(): void {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
  }
  ngAfterViewInit(): void{
    this.getProjectData();
    this.dataCollectorData();
  }
  private getProjectData()
  {
    let params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id);
    let URI = `${UriList.GETCOLLECTIONFORM}`;
    // console.log(params)
    return this.ApiService.getByParams(URI,{params:params}).pipe(first()).subscribe(r => {
      r['data'].forEach((v) => v.form_status += '');
      // console.log(r['data']);
      this.projectsFormData=r['data'];
    });
  }

  downLoadExcelByProjectId(data){

    //  /* pass here the table id */
    //  let element = document.getElementById('excel-table');
    //  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  
    //  /* generate workbook and add the worksheet */
    //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    //  /* save to file */  
    //  XLSX.writeFile(wb, this.fileName);
    // return 0;
    let URI =`${UriList.EXPORTROWDATACOLLECTION}` ;
    let encodedString = btoa(data);
    console.log(encodedString); 
    let params = new HttpParams().set("project_form_id",encodedString)
    const DOWNLOAD_URI = URI+'?project_form_id=' + encodedString;
    this.ApiService.getExcel(`${DOWNLOAD_URI}`,{params:params})
    .subscribe(x => {
       var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
       const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        let pipe = new DatePipe('en-IN');

        link.download = "pmel_data_" + pipe.transform(new Date(), 'yyyy-MMM-dd HHMMSS') + ".xlsx";
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    },err => {
        this.toastr.error('Excel data not found','Please try it again', {
          timeOut: 5000,
          positionClass: 'toast-bottom-right',
        });
        console.log(err.error);
      });
  }
  dataCollectorData()
  {
    let managerId = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    let URI =`${UriList.GETDATACOLLECTOR}/${managerId}`;
    return this.ApiService.get(URI).pipe(first()).subscribe(r => {
      // console.log(r['data'])
      this.dataCollector=r['data'];
    });
  }

  shareProject(id:any,project_form_id:any){
    this.projectData.project_id = id;
    this.projectData.project_form_id = project_form_id;
    this.projectData.manager_id = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
  }
  shareFormsToDataCollector(id)
  {
    if(this.projectData.target == 0){
      new swal('Error','Target is required','error');
      return 0;
    }
    // this.projectData.data_collector_id = id;
    // console.log(this.projectData)
    // return 0
    let URI=`${UriList.STORESHAREDPROJECT}`;
    return this.ApiService.create(this.projectData,URI).pipe(first()).subscribe(r => {
        // console.log(r['data']);
        this.projectData = {};
        document.getElementById("shareProject").click();
        new swal('Success','You have shared sucessfully','success');
      },error=>{
        // console.log(error.error.errors)
        this.projectData = {};
        document.getElementById("shareProject").click();
        new swal('Error',error.error.errors,'error');
      });
    }
    onSortClick(event) {
      let target = event.currentTarget,
        classList = target.classList;
      if (classList.contains('fa-chevron-up')) {
        classList.remove('fa-chevron-up');
        classList.add('fa-chevron-down');
        this.sortDir=-1;
      } else {
        classList.add('fa-chevron-up');
        classList.remove('fa-chevron-down');
        this.sortDir=1;
      }
      this.sortArr('form_status');
    }
    sortArr(colName:any){
      console.log(this.projectsFormData)
      this.projectsFormData.sort((a,b)=>{
        a= a[colName]?.toLowerCase();
        b= b[colName]?.toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    }
}
