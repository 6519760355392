<div class="container" id="excel-table">
  <div class="card-rounded mt-5 mb-5">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">PROJECT LIST</h4>
    </div>
<div class="container">
<div class="mt-4 col-md-11">
  <!-- <button class="btn btn-outline-primary pull-right  mb-4" data-toggle="modal" data-target="#cretaProject" disabled>
    Create Project
  </button> -->
</div>
<div class="col-md-12 row project_list border-success">
  <div class="col-md-11 mt-4">
    <div class="form-group col-4 offset-8">
      <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterTerm">
    </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Project Name</th>
        <th scope="col">Code</th>
        <th scope="col">Manager Name</th>
        <th scope="col">Manager Email</th>
        <th scope="col">Region</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let v of projectsData | filter:filterTerm | paginate: { itemsPerPage: 7, currentPage: p }">
        <th scope="row">{{v.id}}</th>
        <td>{{v.name}}</td>
        <td>{{v.project_code}}</td>
        <td>{{v.project_manager}}</td>
        <td>{{v.email}}</td>
        <td>{{v.region}}</td>
        <td><button class="btn btn-danger">
          <i class="fa fa-trash " (click)="deleteProject(v.id)"></i>
        </button>
        </td>
      </tr>     
    </tbody>
  </table>
  <div class="pagination">
		<pagination-controls (pageChange)="p = $event"></pagination-controls>
	</div>
  </div>
  </div>
</div>
<div class="modal fade" id="cretaProject" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Project</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form name="projectForm">
      <div class="modal-body">
        <label> Project Name</label>
        <input type="text" name="projectName"ngModel required [(ngModel)]="model.name" 
        class="form-control" placeholder="enter project name" minlength="4"/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="submit" *ngIf="1" (click)="saveProject()" class="btn btn-outline-primary">Save Project</button>
      </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>