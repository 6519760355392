import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { UriList } from '../../uri.list';

@Component({
  selector: 'app-project-managers-list',
  templateUrl: './project-managers-list.component.html',
  styleUrls: ['./project-managers-list.component.css']
})
export class ProjectManagersListComponent implements OnInit {
  projectsManagersData:any=[];
  p: number = 1;
  name:string;
  filterTerm:string;
  constructor(private ApiService:ApiService) { }

  ngOnInit(): void {
    this.getManagersData();
  }

  async getManagersData()
  {
    let URI = `${UriList.GETPROJECTMAGAGERS}`;
    return await this.ApiService.get(URI).pipe(first()).subscribe(r => {
      this.projectsManagersData=r['data'];
      console.log(r['data'])
    });
  }
  deleteManager(id){
    new swal({
      title: 'Are you sure?',
      text: "Do you want to remove?",
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
        console.log(result.value)
        // return this.ApiService.delete("get-project-managers/"+id).pipe(first()).subscribe(r =>{
        //   this.getData();
        //   new swal('warning','Deleted sucessfully','warning');
        // });
      }
    });
  }
}
