
<div class="c1">
  <div class="d1">   
      <div style="margin-top: 100px;">
        <h1 class="a1">LOGIN</h1>
      </div>
      <form class="form" #f="ngForm" autocomplete="off" [formGroup]="loginformdata" (ngSubmit)="onClickSubmit(loginformdata.value, null)">
         <label for="username" >Email</label>
         <input type="text" id="username" name="email" placeholder="Enter Email" formControlName="email" >
         <div *ngIf="errorMessage.email" style="color: red; margin-left: 45px;">email is required</div>

         <label for="password" >Password</label> <br>
         <input type="password" id="password" name="password" placeholder = "Enter Password" formControlName = "password">
         <div *ngIf="errorMessage.password" style="color: red; margin-left: 45px;">Password is required</div>

         <button type="submit" class="button button1"><b>LOGIN</b></button>
      </form>
      <div *ngIf="errorMessage.message" style="color: red; margin-left: 45px;">Sorry! Please enter valid input</div>
  </div>
  
  
  <div class="women_vector">
      <img src="assets/images/login/women_vector.png">
  </div>
  <div class="mobile1 ">
      <img src="assets/images/login/mobile_left_front.png" class="rotate linear infinite">
  </div>
  <div class="mobile2 ">
      <img src="assets/images/login/mobile_right_front.png" class="rotate linear infinite">
  </div>
  <div class="women_vector1">
    <img src="assets/images/login/login_women_vector.png">
</div>
  <div class="mobile3 ">
    <img src="assets/images/login/login_mobile1.png" class="rotate linear infinite">
</div>
<div class="mobile4 ">
  <img src="assets/images/login/login_mobile2.png" class="rotate linear infinite">
</div>
  
  <div class="polygon"></div>
    <h1 class="polygon_text ">PMEL</h1>
  </div>

<div class="row pt-5" *ngIf="0">
  <div class="col-md-6 mx-auto">
    <div class="card rounded-0">
      <div class="card-header">
        <h3 class="mb-0">Login</h3>
      </div>
       <!-- desktop view as datacollector -->
      <div class="card-body" id="deskContent">
        <form class="form" #f="ngForm" autocomplete="off" [formGroup]="loginformdata" (ngSubmit)="onClickSubmit(loginformdata.value, null)">
          <div *ngIf="errorMessage.message" style="color: red;">Sorry! Please enter valid input</div>
          <div class="form-group mb-3">
            <label for="password">Email</label> 
            <input type="text" class="form-control" name="email" placeholder="email" 
              formControlName="email" >
              <div *ngIf="errorMessage.email" style="color: red;">email is required</div>
           </div>
           <div class="form-group mb-3">
            <label for="password">Password</label>
            <input type="password" class = "form-control" name="password" 
              placeholder = "password" formControlName = "password">
              <div *ngIf="errorMessage.password" style="color: red;">Password is required</div>
           </div>
          <div class="buttonDiv">
            <button type="submit" class="btn btn-outline-primary loginbutton mb-3" id="deskContent">Login</button>
            <button type="reset" [disabled]="true" (click)="reset()" class="btn btn-outline-danger loginbutton">Reset</button>
          </div>          
        </form>
      </div>
      <!-- mobile view as datacollector -->
      <div class="card-body" id="mobileContent">
        <form class="form" #f="ngForm" autocomplete="off" [formGroup]="loginformdata" (ngSubmit)="onClickSubmit(loginformdata.value, 5)">
          <div *ngIf="errorMessage.message" style="color: red;">Sorry! Please enter valid input</div>
          <div class="form-group mb-3">
            <label for="password">Email</label> 
            <input type="text" class="form-control" name="email" placeholder="email" 
              formControlName="email" >
              <div *ngIf="errorMessage.email" style="color: red;">email is required</div>
           </div>
           <div class="form-group mb-3">
            <label for="password">Password</label>
            <input type="password" class = "form-control" name="password" 
              placeholder = "password" formControlName = "password">
              <div *ngIf="errorMessage.password" style="color: red;">Password is required</div>
           </div>
          <div class="buttonDiv">
            <button type="submit"  class="btn btn-outline-primary loginbutton mb-3" >Data Collector Login</button>
            <button type="reset" [disabled]="true" (click)="reset()" class="btn btn-outline-danger loginbutton">Reset</button>
          </div>          
        </form>
      </div>
    </div>
  </div>

</div>
