<div class="container" id="excel-table">
  <div class="card-rounded mt-5 mb-5">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">PROJECT MANAGERS LIST</h4>
    </div>
<div class="container">
    <div class="col-md-12 row">
      <div class="col-md-12 mt-4">
        <div class="form-group col-4 offset-8">
          <input type="text" class="form-control" placeholder="Search managers" [(ngModel)]="filterTerm">
        </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Manager Name</th>
            <th scope="col">Manager Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let v of projectsManagersData | filter:filterTerm | paginate: { itemsPerPage: 10, currentPage: p }">
            <td scope="row">{{v.user_id}}</td>
            <td>{{v.name}}</td>
            <td>{{v.email}}</td>
            <td><button class="btn btn-danger">
              <i class="fa fa-trash " (click)="deleteManager(v.user_id)"></i>
            </button>
            </td>
          </tr>     
        </tbody>
      </table>
      <div class="pagination">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
      </div>
    </div>
</div>
</div>