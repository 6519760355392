<div class="container">
  <div >
    <div class="form-header">
      <!-- <h3>PMEL data collection  </h3> -->
      <div class="col-md-12 mt-5 block">
        <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
        <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
        <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
        <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
         <h4 style="text-align: center;" class="mt-1 ">CREATE DATA COLLECTION FORM  </h4> 
      </div>

          <div class="a7">
            <div class="p11"> 
              <b><label for="validationDefault02">Data collection sample size:</label></b>
              <input type="number" id="target" placeholder="Data collection sample size" name="target" [(ngModel)]="collectionForm.target" class="p12">
            </div>
            <div class="p3"> 
              <b><label for="validationDefault02" placeholder="All Regions">Regions:</label></b>
              <select [disabled]="(loggedInRoleId == '2') ? true : false" class="custom-select form-control" [(ngModel)]="collectionForm.region_id" (change)="getRegionId($event.target.value)" >
                  <option value="">Select option</option>
                  <option *ngFor="let rd of regionDropdownList" value="{{rd.id}}" >{{rd.name}}</option>
                </select>
            </div>
              <div class="p4"> 
                <b><label for="validationDefault02">Stakeholder:</label></b>
                <select class="form-control custom-select3" [(ngModel)]="collectionForm.stakeholder_id" (change)="getStakeholdersByIdData($event.target.value)" >
                  <option value="" selected disabled>Select option</option>
                  <option *ngFor="let x of stakeholders" value="{{x.id}}" >{{x.name}}</option>
                </select>
              </div>
              <div class="p5" > 
                  <b><label for="validationDefault02">Projects:</label></b>
                  <select class="form-control custom-select1" [(ngModel)]="collectionForm.project_id" (change)="getProjectName($event.target.value)">
                    <option value="" selected disabled>Select option</option>
                    <option *ngFor="let project of projectDropdownList" value="{{project.id}}" >{{project.name}}</option>
                  </select>
                 
              </div>
              <div class="p6"> 
                  <b><label for="validationDefault02">KPIs:</label></b>
                  <ng-multiselect-dropdown 
                    [settings]="dropdownSettings"                    
                    [data]="dropdownList" 
                    [(ngModel)]="outcomeKpisObj" 
                    [title]="dropdownList['name']"                   
                    (click)="getArrayofKpiFormsData()"
                    [disabled]="buttonDisabled"             
                    >
                  </ng-multiselect-dropdown>
              </div>
              <div class="p7"> 
                <b><label for="validationDefault02">Start Date:</label></b>
                <input type="date" id="from_date" name="from_date" class="from_date" [(ngModel)]="collectionForm.from_date">
              </div>
              <div class="p8"> 
                <b><label for="validationDefault02">End Date:</label></b>
                <input type="date" id="to_date" name="to_date" class="to_date" [(ngModel)]="collectionForm.to_date">
              </div>
            <button class="btn-primary"  (click)="createForm()">Create Form</button>
          </div>
      </div>
    </div>
    
    <div class="col-md-12" *ngIf="stakeholderSuccess" id="viewAnswer">
      <form class="ftheme" >  
      <div class="col-md-12 m-4" >
        <h4 class="viewAnswer-heading" ># Stakeholder Questions</h4> <br>
        <div *ngFor="let stakeholderItem of stakeholdersByIdData; let i = index" class="form-group" >
          <div *ngIf="stakeholderItem.type=='text'">
           <b>{{i +1}}. </b> <label> {{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
           <div *ngIf="stakeholderItem.type=='group_text'">
            <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          </div>
          <div *ngIf="stakeholderItem.type=='email'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          <div *ngIf="stakeholderItem.type=='phone'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
           </div>
          <div *ngIf="stakeholderItem.type=='number'">
             <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
            </div>
          <div *ngIf="stakeholderItem.type=='date'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          <div *ngIf="stakeholderItem.type=='datetime-local'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          <div *ngIf="stakeholderItem.type=='textarea'">
             <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          <div *ngIf="stakeholderItem.type=='paragraph'">
            <p class="form-text">{{stakeholderItem.placeholder}}</p>
          </div>
          <div *ngIf="stakeholderItem.type=='autocomplete'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
            <!-- <small class="form-text text-muted">{{stakeholderItem.description}}</small> -->
          </div>
          <div *ngIf="stakeholderItem.type=='checkbox'">
           <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
          <div *ngIf="stakeholderItem.type=='radio'">
             <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
          </div>
        </div>
      </div>
      </form>
    </div>

    <div class="col-md-12" *ngIf="success" id="viewAnswer">
      <div *ngFor="let kpiData of kpisFormsData">
      <form class="ftheme" >  
      <div class="col-md-12 m-4" >
        <h4 class="viewAnswer-heading" >{{kpiData.kpi_data.kpi}}</h4> <br>
        <div *ngFor="let item of kpiData.kpi_data.question_data; let i = index" class="form-group" >
          <div *ngIf="item.type=='text'">
           <b>{{i +1}}. </b> <label> {{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
           <div *ngIf="item.type=='group_text'">
            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          </div>
          <div *ngIf="item.type=='email'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div *ngIf="item.type=='phone'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
           </div>
          <div *ngIf="item.type=='number'">
             <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            </div>
          <div *ngIf="item.type=='date'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div *ngIf="item.type=='datetime-local'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div *ngIf="item.type=='textarea'">
             <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div *ngIf="item.type=='paragraph'">
            <p class="form-text">{{item.placeholder}}</p>
          </div>
          <div *ngIf="item.type=='autocomplete'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <!-- <small class="form-text text-muted">{{item.description}}</small> -->
          </div>
          <div *ngIf="item.type=='checkbox'">
           <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div *ngIf="item.type=='radio'">
             <b>{{i +1}}. </b> <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
        </div>
      </div>
      </form>
    </div>
    </div>
  </div>











