<div class="container" id="excel-table">
  <div class="card-rounded mt-5 mb-5">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">KPI LIST</h4>
    </div>
    <div class="container">
      <div class="col-md-12 row">
          <div class="col-12 row mt-4 mb-4">
            <div class="col-6">
              <input type="text" class="form-control" placeholder="Search kpis" [(ngModel)]="filterTerm">  
            </div>
            <div class="custom-control custom-switch pull-right col-6 mt-1"  *ngIf="loggedInRoleId == 2">
              <input type="checkbox" class="custom-control-input" id="switch" [(ngModel)]="recOrNetKpiView" (change)="(recOrNetKpiView != 1) ? getRecKpisData() : getKpiListData()">
              <label class="custom-control-label" for="switch"><span *ngIf="recOrNetKpiView == 1"></span><span *ngIf="recOrNetKpiView == 0"></span></label>
              <label *ngIf="recOrNetKpiView == 1">Network KPIs</label>
              <label *ngIf="recOrNetKpiView != 1">Rec KPIs</label>
              <button class="btn btn-outline-warning pull-right" routerLink="/create-kpi">Create Kpi</button>
            </div>
          </div>
          <table class="table">
          <thead>
            <tr>
            <th scope="col">Id <i (click)="onSortClick($event)" class="my-icon fa fa-chevron-up"></i> </th>
            <th scope="col">KPI Title</th>
            <th scope="col">Kpi Number </th>
            <th scope="col">Indicator Code </th>
            <th>Action</th>
            </tr>
          </thead>
          <tbody  *ngIf="kpiListData?.length; else no_data_templ">

          <tr *ngFor="let v of kpiListData |filter:filterTerm | paginate: { itemsPerPage: 6, currentPage: p } let i = index  ">
            <th scope="row">{{v.id}}</th>
            <td>{{v.title}}</td>
            <td>{{v.kpi_number}}</td>
            <td>{{v.indicator_code}}</td>
            <td><button class="btn btn-danger" *ngIf="loggedInUserId == v.user_id; else  notInUserId">
              <i class="fa fa-trash " (click)="deleteKpi(v.id)"></i>
            </button>
            </td>
          </tr>     
          <tr *ngIf="(kpiListData |filter:filterTerm).length == 0" >
            <td  colspan="5" style="background-color: brown;">No Data found</td>
          </tr>
          </tbody>
          </table>
          <div class="pagination">
             <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
          <ng-template #no_data_templ>
            No daata found...
          </ng-template>
          <ng-template #notInUserId>-</ng-template>
      </div>
    </div>
  </div>
</div>


