<div class="col-10 offset-1 mt-4">
  <div class="card rounded-0">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">CREATE KPIs</h4>
    </div>
    <div class="col-8 offset-2">
      <div class="form-group">
        <div class="mt-5">
          <label>Name <i style="color: red;">*</i></label>
          <input type="text" class="form-control" placeholder="Enter KPI name" [(ngModel)]="model.title">
        </div>
        <div class="mt-3">
          <label>KPI Number <i style="color: red;">*</i></label>
          <input type="text" class="form-control" placeholder="Enter KPI number" [(ngModel)]="model.kpi_number">
        </div>
        <div class="mt-3">
          <label>Indicator Code <i style="color: red;">*</i></label>
          <input type="text" class="form-control" placeholder="Enter indicator code" [(ngModel)]="model.indicator_code">
        </div>
        <div class="mt-4">
          <label> Outcome Indicator <i style="color: red;">*</i></label>
            <ng-multiselect-dropdown
                [settings]="dropdownSettings"
                [data]="dropdownList" [(ngModel)]="outcomeIndicatorObj" [title]="dropdownList['name']">
            </ng-multiselect-dropdown>
        </div>
        <div class="mt-4">
          <label>Project<i style="color: red;">*</i></label>
            <ng-multiselect-dropdown
                [settings]="dropdownProjectSettings"
                [data]="dropdownProjectList" [(ngModel)]="outcomeProjectIndicatorObj" [title]="dropdownProjectList['name']">
            </ng-multiselect-dropdown>
        </div>
        <div  class="mt-4">
          <label> Rational</label>
          <input type="text" class="form-control" placeholder="KPI rational(optional)" [(ngModel)]="model.rational">
        </div>
        <div  class="mt-4">
          <label> Definition</label>
          <input type="text" class="form-control" placeholder="KPI definition(optional)" [(ngModel)]="model.definition">
        </div>
        <div>
      </div>
      <div class="mb-4 mt-4">
        <button type="submit" (click)="submit()" class="btn btn-outline-primary" style="margin-right: 5px;">Add KPI</button>
        <button type="reset" (click)="reset()" value="Submit" class="btn btn-outline-danger">Reset</button>
      </div>
    </div>
    </div>
</div>