import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { UriList } from '../../uri.list';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-kpi-list',
  templateUrl: './kpi-list.component.html',
  styleUrls: ['./kpi-list.component.css']
})
export class KpiListComponent implements OnInit {

  kpiListData:any;
  p: number = 1;
  name:string;
  filterTerm:string;
  loggedInData: string;
  loggedInUserName:string;
  loggedInRoleId:number;
  loggedInUserId:number;
  loggedInRecId:number;
  recOrNetKpiView:number=1;
  sortDir = 1;//1= 'ASE' -1= DSC
  
  constructor(private ApiService:ApiService) { }

  ngOnInit(): void {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.loggedInUserId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).user_id : 0)
    this.loggedInRecId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).region_id : 0)
    this.getKpiListData()
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  async getKpiListData()
  {
    let URI =`${UriList.GETKPIS}`;
    let params=new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id);;
    // if(this.loggedInRecId == null){
      // params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id);
    // }else{
      // params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id);
    // }
    await this.ApiService.getByParams(URI,{params:params}).pipe(first()).subscribe(r => {
      this.kpiListData=r['data'];
      // console.log(r['data'])
    });
  }
  async getRecKpisData()
  {
    await this.ApiService.getById(this.loggedInRecId, UriList.GETKPISCREATEDBYREC).pipe(first()).subscribe(res =>{
      this.kpiListData = res['data'];
      // console.log(res['data'])
    })
  }
  async deleteKpi(id){
    new swal({
      title: 'Are you sure?',
      text: "Do you want to remove?",
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
        console.log(result.value)
        return this.ApiService.delete("project/"+id).pipe(first()).subscribe(r =>{
          this.getKpiListData();
          new swal('warning','Deleted sucessfully','warning');
        });
      }
    });
  }

  onSortClick(event) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir=-1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir=1;
    }
    this.sortArr('id');
  }
  sortArr(colName:any){
    this.kpiListData.sort((a,b)=>{
      a= a[colName];
      b= b[colName];
      return a.toString().localeCompare(b.toString(),'en', {numeric: true}) * this.sortDir;
    });
  }
}
