import { Component, OnInit } from '@angular/core';
import { User } from '../../global.model';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers/users.reducers';
import * as UserActions from '../../store/actions/users.actions'
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {

  demo: Observable<User[]>;
  
  constructor(private store: Store<UserState>) {
    this.demo = store.select('userStore');
  }

  removeFromTable(id: number) {
    this.store.dispatch(new UserActions.RemoveUser(id)); 
  }

  ngOnInit() {
  }

}
