import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../allServices/auth.service';
import {ToastrService} from 'ngx-toastr';
import { FormBuilder,FormControl, FormGroup } from '@angular/forms';
import {UriList} from '../../uri.list';
import {Store} from '@ngrx/store';
import * as LoginAction from '../../store/actions/login.action';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  constructor(private _auth: AuthService, 
    private router: Router,
    private toastr:ToastrService,
    private formBuilder: FormBuilder,
    private store:Store){
  }
  ngOnInit() {
  }

  loginformdata:FormGroup = new FormGroup({
    email: new FormControl(null),
    password: new FormControl(null),
    role_id: new FormControl(null)
 });
  errorMessage:any ={
    email:'',
    password: '',
    message: false
  };
  loginUserData:any = {
    email:'',
    password: '',
    role_id:''
  }

  onClickSubmit(data, role_id) {

    // this.store.dispatch(new LoginAction.LoginUser({email:data.email,password:data.password,role_id:role_id}))


    this._auth.loggedInUser(`${UriList.LOGIN}`,data)
    .subscribe(
      res => {
        if(res.data.role_id == 5){
          this.toastr.error('You can use mobile app and collect data','Please enter valid input', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
          });
          return 0;
        }
        if(role_id == null){
          localStorage.setItem('token', (res.data.token));
          localStorage.setItem('user_data', (JSON.stringify(res.data)));
          // this.toastr.success('Logged In Successfully', 'Success!', {
          //   positionClass: 'toast-bottom-right',
          //   timeOut: 5000
          // });
          if(res.data.role_id == 1 || res.data.role_id == 4){
            this.router.navigate(['/home']);
            return 0;
          }
          if(res.data.role_id == 2){
            this.router.navigate(['/home']);
            return 0;
          }
          // console.log(res.data.role_id)
        }else if(res.data.role_id == role_id){
          localStorage.setItem('token', (res.data.token));
          localStorage.setItem('user_data', (JSON.stringify(res.data)));
          // this.toastr.success('Logged In Successfully', 'Success!', {
          //   positionClass: 'toast-bottom-right',
          //   timeOut: 5000
          // });
          this.router.navigate(['/home']);
        }else{
          this.toastr.error('Data collector not found','Please enter valid input', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
          });
          return 0;
        }
      },
      err => {
        this.errorMessage.message = err.error.errors;
        this.errorMessage.email = err.error.errors[1];
        this.errorMessage.password = err.error.errors[0];
        this.toastr.error('everything is broken','Please enter valid input', {
          timeOut: 5000,
          positionClass: 'toast-bottom-right',
        });
        setTimeout (() => {
          this.errorMessage.email = "";
          this.errorMessage.password = ""
        }, 2000);
        // console.log(err.error.errors[0]);
      }
    )
  }
  loggedIn(){
    return !!localStorage.getItem('token');
  }
  reset(){
    this.loginUserData={};
    this.errorMessage.message="";
  }

}
