import { Component, OnInit } from '@angular/core';
import { ApiService } from '../allServices/api.service';
import { first } from 'rxjs/operators';
import { UriList } from '../uri.list';
// import {routeAnimations} from '../animation';
declare var anime: any;   

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // animations: [routeAnimations],
})

export class HomeComponent implements OnInit {

  public loggedInUserName:string;
  public loggedInData: any;
  public loading:boolean;
  public loggedInRoleId:string;
  
  constructor(private ApiService:ApiService) { }

  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.getDashboardData();
    // console.log( sessionStorage.getItem('test') )
  }
  ngAfterViewInit(): void {
    // Wrap every letter in a span
    const textWrapper = document.querySelector('.an-1');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: true})
    .add({
      targets: '.an-1 .letter',
      scale: [4,1],
      opacity: [0,1],
      translateZ: 0,
      easing: "easeOutExpo",
      duration: 950,
      delay: (el, i) => 70*i
    }).add({
      targets: '.an-1',
      opacity: 0,
      duration: 100,
      easing: "easeOutExpo",
      delay: 1000
    });

  }
  dashboardData:any = {
    total_kpis:'',
    projects:'',
    project_managers:'',
    ongoing_collection:'',
    collection_completed:'',
    data_collectors:'',
    added_questions:'',
    total_forms:'',
    accepted_forms:'',
    rejected_forms:'',
    data_collectors_active:'',
    data_collectors_inactive:'',
    total_collection_forms:'',
    network_kpis:'',
    pending_forms:'',
    rec_kpis:'',
    
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  async getDashboardData()
  {
    this.loading = true;
    let params = JSON.parse(this.loggedInData);
    return await this.ApiService.getByParams(`${UriList.DASHBOARD}/${JSON.parse(this.loggedInData).user_id}`,params).pipe(first()).subscribe(r => {
      // this.dashboardData=r['data'];
      // console.log(r['data'])
      this.dashboardData.total_kpis=r['data'].total_kpis;
      this.dashboardData.projects=r['data'].projects;
      this.dashboardData.project_managers=r['data'].project_managers;
      this.dashboardData.ongoing_collection=r['data'].ongoing_collection;
      this.dashboardData.collection_completed=r['data'].collection_completed;
      this.dashboardData.data_collectors=r['data'].data_collectors;
      this.dashboardData.added_questions=r['data'].added_questions;
      this.dashboardData.total_forms=r['data'].total_forms;
      this.dashboardData.accepted_forms=r['data'].accepted_forms;
      this.dashboardData.rejected_forms=r['data'].rejected_forms;
      this.dashboardData.data_collectors_active=r['data'].data_collectors_active;
      this.dashboardData.data_collectors_inactive=r['data'].data_collectors_inactive;
      this.dashboardData.total_collection_forms=r['data'].total_collection_forms;
      this.dashboardData.network_kpis=r['data'].network_kpis;
      this.dashboardData.pending_forms=r['data'].pending_forms;
      this.dashboardData.rec_kpis=r['data'].rec_kpis;
      this.loading = false;
    });
  }
}
