<div class="container" id="excel-table">
  <div class="card-rounded mt-5 mb-5">
        <div class="card-header">
          <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
          <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
          <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
          <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
          <h4 class="mt-1 mb-1" style="text-align: center; ">EXPORT DATA BY FORM</h4>
        </div>
        <div class="container">
            <div class="form-group col-4 offset-8 mt-4 mb-4">
              <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterTerm">
            </div>
            <div class="col-md-12 row">
               <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" [width]="40">Name</th>
                    <th>Status <i (click)="onSortClick($event)" class="my-icon fa fa-chevron-up"></i></th>
                    <th scope="col">Region</th>
                    <th scope="col">Sample Size</th>
                    <th scope="col">Collected Data</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th>Export</th>
                  </tr>
                </thead>
                <tbody *ngIf="projectsFormData?.length; else no_data_templ">
                  <tr *ngFor="let v of projectsFormData |filter:filterTerm | paginate: { itemsPerPage: 6, currentPage: p } let i = index  ">
                    <th scope="row">{{i+1}}</th>
                    <td >{{v.project_form_id}} 
                      <a routerLink="/form-based-questions/{{v.project_id}}/{{v.project_form_id}}/{{(v.form_status == null) ? 0 : v.form_status}}">({{v.project_code}})</a>
                    </td>
                    <td>
                      <span *ngIf="v.form_status == 2"><i class="fa fa-times" aria-hidden="true"></i></span>
                      <span *ngIf="v.form_status == null">-</span>
                      <span *ngIf="v.form_status == 1"><i class="fa fa-check" aria-hidden="true"></i></span>
                      <button *ngIf="v.form_status == 1 && loggedInRoleId == 4" class="btn btn-outline-default" (click)="shareProject(v.project_id,v.project_form_id)" data-toggle="modal" data-target="#shareProject">
                        <i class="fa fa-share-alt" ></i>
                      </button>
                    </td>
                    <td>{{v.region}}</td>
                    <td>{{v.target}}</td>
                    <td>{{v.collected_target}}</td>
                    <td>{{v.to_date}}</td>
                    <td>{{v.from_date}}</td>
                    <td>
                        <button class="btn" (click)="downLoadExcelByProjectId(v.project_form_id)"><i class="fa fa-file-excel-o " ></i> </button>
                    </td>
                  </tr>  
                  <tr *ngIf="(projectsFormData |filter:filterTerm).length == 0" >
                    <td  colspan="9" style="background-color: brown;">No Data found</td>
                  </tr>   
                </tbody>
              </table>
            <div class="pagination">
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
            <ng-template #no_data_templ>
              No daata found...
            </ng-template>
        </div>
    </div>
</div>


<div class="modal fade" id="shareProject" tabindex="-1" role="dialog" aria-labelledby="shareProjectLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="shareProjectLabel">Share project</h5>
        <button type="button" class="btn-outline-danger" data-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <label>Select Data Collector <i style="color: red;">*</i></label>
          <h6><select class="form-control" [(ngModel)]="projectData.data_collector_id" >
            <option *ngIf="projectData.data_collector_id == 0" selected>Select option</option>
            <option *ngFor="let x of dataCollector" value="{{x.user_id}}">{{x.name}}</option>
          </select> </h6>
          <label>Select Target <i style="color: red;">*</i></label>
          <h6><input type="text" class="form-control" [(ngModel)]="projectData.target" /></h6>
        </div>
      </div>
      <div class="modal-footer">
        <button type="reset" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        <button type="submit" (click)="shareFormsToDataCollector(projectData.data_collector_id)" class="btn btn-outline-share">Share</button>
      </div>
    </div>
  </div>
</div>
