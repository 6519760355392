<div class="container">
  <div class="card-rounded mt-5 mb-5">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">COLLECTOR</h4>
    </div>
  <div class="container">
    <div class="col-md-12">
      <div class="mb-4 mt-4 col-md-11">
        <button type="button" class="btn btn-outline-userplus pull-right" data-toggle="modal" data-target="#dataCollector">
          <i class="fa fa-user-plus"></i> Create Data Collector
        </button>
      </div>
  <div class="form-group col-4 mb-4 mt-4">
    <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="filterTerm">
  </div>
  <div class="row" [@listAnimation]="dataCollector.length">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Target</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngIf="dataCollector?.length; else no_data_templ">
        <tr *ngFor="let v of dataCollector |filter:filterTerm let i = index "  [@flyInOut]="'in'">
          <th scope="row">{{i+1}}</th>
          <td>{{v.name}}</td>
          <td>{{v.email}}</td>
          <td>{{v.target}}</td>
          <td  ><button class="btn btn-danger btn-rounded">
            <i class="fa fa-trash" (click)="deleteDataCollector(v.user_id,v.target)"></i> 
          </button>
          </td>
        </tr> 
        <tr *ngIf="(dataCollector |filter:filterTerm).length == 0" >
          <td  colspan="9" style="background-color: brown;">No Data found</td>
        </tr>     
      </tbody>
    </table>
    </div>
    <ng-template #no_data_templ>
      No daata found...
    </ng-template>
    </div>
    <div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="dataCollector" tabindex="-1" aria-labelledby="dataCollectorLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dataCollectorLabel">Add collector</h5>
          <button type="button" class="btn-outline-danger" data-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <div class="col-12 row">
            <div class="mt-2 mb-2 col-12">
              <label> Name</label>
              <input type="text" name="projectName" required [(ngModel)]="userModel.name" 
              class="form-control" placeholder="enter name" minlength="4"/>
            </div>
            <div class="mt-2 mb-2 col-12">
              <label> Email</label>
              <input type="email" name="projectName" required [(ngModel)]="userModel.email" 
              class="form-control" placeholder="enter email" minlength="4"/>
            </div>
            <div class="mt-2 mb-2 col-12">
              <label> Password</label>
              <input type="text" name="projectName" required [(ngModel)]="userModel.password" 
              class="form-control" placeholder="enter password" minlength="4"/>
            </div>
          <div class="mt-2 mb-2 col-12">
            <label> Phone</label>
              <input type="number" name="projectName" required [(ngModel)]="userModel.mobile" 
              class="form-control" placeholder="enter phone" minlength="4"/>
          </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary btn-rounded" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-outline-save" (click)="saveDataCollector()">Save</button>
        </div>
      </div>
    </div>
  </div>
