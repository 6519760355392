import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

      let currentUser = JSON.parse(localStorage.getItem('user_data'));
      if(currentUser == null){
        this.router.navigate(['/login']);
   
        return false;
      }
      if (currentUser.role_id == 1) {
          return true;
      }
      this.router.navigate(['/'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
  }
}
