<div class="col-8 offset-2 mt-4">
    <div class="card rounded-0">
      <div class="card-header">
        <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
        <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
        <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
        <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
        <h4 class="mt-1 mb-1" style="text-align: center;">PASSWORD RESET</h4>
      </div>
      <div class="col-12 offset-2">
        <div class="form-group">
            <div class="container">
                <div class="row">
                    <div class="col-8 mt-5">
                        
                        <label>Current User Id</label>
                        <div class="form-group pass_show"> 
                            <input type="text" [(ngModel)]="loggedInUserName" class="form-control" [disabled]="true"> 
                        </div> 
                           <label>New Password</label>
                        <div class="form-group pass_show"> 
                            <input type="password" [(ngModel)]="model.password" class="form-control" placeholder="New Password"> 
                        </div> 
                           <label>Confirm Password</label>
                        <div class="form-group pass_show"> 
                            <input type="text" [(ngModel)]="model.confirm_password" class="form-control" (keyup)="confirm_password($event.target.value)" placeholder="Confirm Password"> 
                        </div> 
                        <div *ngIf="booleanVal" style="color: red;">Sorry! Password does not match.</div>
                        
                    </div>  
                </div>
            </div>  
            <div class="mb-4 mt-4 ml-3">
                <button type="submit" (click)="updatePassword()" class="btn btn-outline-warning mr-2" style="margin-right: 5px;">UPDATE</button>
                <button type="reset" (click)="restPassword()" value="Submit" class="btn btn-outline-danger ">RESET</button>
              </div>          
        </div>
   
      </div>
      </div>
  </div>