import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { ApiService } from '../../allServices/api.service';
import { UriList } from '../../uri.list';


@Component({
  selector: 'app-get-answer',
  templateUrl: './get-answer.component.html',
  styleUrls: ['./get-answer.component.css']
})
export class GetAnswerComponent implements OnInit {
  projects:any;
  collectors:any;
  serachResultData:any;
  projectsData:any=[];
  p: number = 1;
  public loggedInData:any;
  public collectedData:any;
  public projectsFormData:any;

  constructor(private ApiService: ApiService) { }

  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.projectList();
    this.collectorsList();
    this.getProjectData();
  }

  model:any={
    project_form_id:'',
    project_id:'',
    user_id:''
  }
  projectList(){
    let params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id)
    this.ApiService.getByParams(`${UriList.GETPROJECT}`,{params:params}).pipe(first()).subscribe(res => {
      // console.log(res['data'])
      this.projects=res['data'];
    });
  }
  private getProjectData()
  {
    let params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id);
    let URI = `${UriList.GETCOLLECTIONFORM}`;
    // console.log(params)
    return this.ApiService.getByParams(URI,{params:params}).pipe(first()).subscribe(r => {
      r['data'].forEach((v) => v.form_status += '');
      // console.log(r['data']);
      this.projectsFormData=r['data'];
    });
  }
  collectorsList(){
    let managerId = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    this.ApiService.get(`${UriList.GETDATACOLLECTOR}/${managerId}`).pipe(first()).subscribe(r => {
      // console.log(r['data'])
      this.collectors=r['data'];
    });
  }
  serachResult(){
    let params = new HttpParams().set("project_form_id",this.model.project_form_id);
    this.ApiService.getByParams(`${UriList.GETEXPORTWITHANSWER}`,{params:params}).pipe(first()).subscribe(r => {
      this.collectedData=r['data'];
      if(Object.keys(r['data']).length === 0){
        new swal('Error','No data found');
        return false;
      }
      this.serachResultData = r['data'];
    });
  }
}
