import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { UriList } from '../../uri.list';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {
  projectsData:any=[];
  p: number = 1;
  name:string;
  filterTerm:string;
  loggedInData: string;
  loggedInUserName:string;
  loggedInRolId:number;
  constructor(private ApiService:ApiService) { }

  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.loggedInRolId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
    this.getData();
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  model:any = {
    name:""
  }
  async getData()
  {
    let URI =`${UriList.GETPROJECT}`;
    let params = new HttpParams().set("user_id",JSON.parse(this.loggedInData).user_id)
    // console.log(params)
    return await this.ApiService.getByParams(URI,{params:params}).pipe(first()).subscribe(r => {
      this.projectsData=r['data'];
      // console.log(r['data'])
    });
  }
  saveProject(){
    let URI =`${UriList.PROJECT}`;
    return this.ApiService.create(this.model,URI).pipe(first()).subscribe(r => {
      this.model.name = "";
      document.getElementById("cretaProject").click();
      new swal('Success','You have created sucessfully','success');
      this.getData();
      // console.log(r['data'])
    });
  }
  deleteProject(id){
    new swal({
      title: 'Are you sure?',
      text: "Do you want to remove?",
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
        console.log(result.value)
        // return this.ApiService.delete("project/"+id).pipe(first()).subscribe(r =>{
        //   this.getData();
        //   new swal('warning','Deleted sucessfully','warning');
        // });
      }
    });
  }
}
