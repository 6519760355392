import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { field, value } from '../../global.model';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { ApiService } from '../../allServices/api.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { UriList } from '../../uri.list';

@Component({
  selector: 'app-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.css']
})
export class DataCollectionComponent implements OnInit {

  dataCollectorId:number;
  currentIndex:number;
  questionsLength:number
  currentQuestionSet:any;
  questions:any;
  questionsTab:any;
  projectsData: any;
  kpis:any = [];
  itemButton:string="";
  questionData:any = [];
  kpi_id:any=[];
  dropdownList = [];
  outcomeIndicatorObj = [];
  loggedInData:any;
  dropdownSettings:IDropdownSettings={};
  constructor(private ApiService: ApiService,private toastr:ToastrService,) { 
    this.questions=[]
    this.loggedInData = localStorage.getItem('user_data');
    this.ansModel.data_collector_id =(this.loggedInData)? JSON.parse(this.loggedInData).user_id : 0;
    this.currentIndex=0;
    this.currentQuestionSet= this.questions[this.currentIndex];
  }
  value:value={
    label:"",
    value:""
  };
  success = false;

  modelFields:Array<field>=[];
  model:any = {
    farmer_profile_id:'',
    data_collector_id:'',
    project_id: '',
    attributes:this.modelFields
  };
  profileModel:any={
    project_id: '',
    age: '',
    gender: 'male',
    household_head: '',
    decision_making: '',
    education: '',
    has_mobile: 'yes',
    has_mobile_internet : 'yes',
    household_size: '',
    location: '',
    commodity_id: '0',
    type_of_land: '',
    producer_organisation_member: 'yes',
    total_workers: '',
    female_workers: '',
    male_workers: '',
    hired_workers: '',
    location_region: '',
    location_town: '',
    total_cultivated_land_size: '',
    primary_commodity_land_size: 0
  }
  
  questionFields:Array<field>=[];
  ansModel:any={
    project_id: '',
    question_id: '',
    project_form_id:'',
    group_question_id: '',
    data_collector_id: '',
    farmer_profile_id: '',
    form_id: '',
    name: '',
    value: '',
    kpi_id:'',
    group_value:'',
    attributes:this.questionFields
  }
  
  ngOnInit() {
    this.getProjectData();
    this.model.data_collector_id = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    this.dropdownList = [];
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true
    };
    // console.log(this.questionData != null)
  }
  ngDoCheck(){
    this.ansModel.farmer_profile_id = localStorage.getItem('farmer_profile_id')
    this.questionsTab = (localStorage.getItem('farmer_profile_id')) ? 0 : 1;
  }
  
  toggleValue(item){
    item.selected = !item.selected;
  }


  async getQuestionStoreAnswer(){
    let valid = 0;
    let validationArray = JSON.parse(JSON.stringify(this.ansModel.attributes));
    validationArray.reverse().forEach(field => {
      // console.log(field.label+'=>'+field.required+"=>"+field.value);
      if(field.required && !this.ansModel.value && field.type != 'checkbox'){
        new swal('Error',''+field.label,'error');
        valid = 1;
        return 0;
      }
      // if(!field.value && field.type != 'checkbox'){
      //   new swal('Error',''+field.label,'error');
      //   valid = 1;
      //   return 0;
      // }
      if(field.required && field.regex){
        let regex = new RegExp(field.regex);
        if(regex.test(field.value) == false){
          new swal('Error',field.errorText,'error');
          valid = 1;
          return 0;
        }
      }
      if(field.required && field.type == 'checkbox'){
        if(field.values.filter(r=>r.selected).length == 0){
          new swal('Error',' '+field.label,'error');
          valid = 1;
          return 0;
        }
      }
    
    });
    if(valid){
      // console.log(validationArray)
      return 0;
    }else{
      this.questionData=[];
      this.currentIndex = this.currentIndex + 1;
      this.currentQuestionSet= this.questions[this.currentIndex];
      let URI =`${UriList.GETQUESTIONSTOREANSWER}`
      return await this.ApiService.create(this.ansModel,URI).pipe(first()).subscribe(r => {
        // console.log(r['success'])
        if(r['success'] != true){
          localStorage.removeItem("farmer_profile_id");
          new swal('Success','Submitted successfully','success');
          this.itemButton = "";
          this.ansModel.group_value='';
        }else{
            this.ansModel.group_value='';
            this.ansModel.question_id=r['data'][0]['question_id'];
            this.ansModel.group_question_id=(r['data'][1])?r['data'][1]['question_id']:'';
            this.ansModel.form_id=r['data'][0]['form_id'];
            this.ansModel.name=r['data'][0]['name'];
            this.ansModel.kpi_id = r['data'][0]['kpi_id'];
            this.ansModel.project_form_id=r['data'][0]['project_form_id'];
            this.ansModel.value='';
            this.toastr.success('successfully answered', 'Success!');
            this.ansModel.attributes = r['data'];
            this.questionData=r['data'];
            // console.log(r['data'])
        }
        
      });
    }
  }

  previousQuestion()
  {
    this.currentIndex = this.currentIndex - 1;
    this.currentQuestionSet= this.questions[this.currentIndex];
  }
  reset(){
    this.model.attributes=[];
  }
  // get project data by collector id
  private getProjectData()
  {
    let dataCollectorId = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    let URI =`${UriList.DATACOLLECTORPROJECT}/${dataCollectorId}`;
    return this.ApiService.get(URI).pipe(first()).subscribe(r => {
      this.projectsData=r['data'];
    });
  }
  getFormByProjectId(id:any)
  {
    this.reset();
    this.profileModel.project_id=id;
    this.dataCollectorId=id;
    let params = new HttpParams().set("project_id",id)
    this.ApiService.getByParams(`${UriList.GETQUESTIONSTOREANSWER}`,{params:params}).pipe(first()).subscribe(questionData => {
      if(Object.keys(questionData['data']).length == 0){
        new swal("", "No data found.", "warning");
        this.itemButton = "";
      }else{
        this.itemButton = "button";
        this.ansModel.project_id=id;
        this.ansModel.question_id=questionData['data'][0]['question_id'];
        this.ansModel.group_question_id=(questionData['data'][1]) ? questionData['data'][1]['question_id'] : '';
        this.ansModel.form_id=questionData['data'][0]['form_id'];
        this.ansModel.name=questionData['data'][0]['name'];
        this.ansModel.kpi_id = questionData['data'][0]['kpi_id']
        this.ansModel.project_form_id=questionData['data'][0]['project_form_id'];
        this.questionsLength=questionData['data'][0]['total_questions'];

        this.questionData=questionData['data'];
        this.ansModel.attributes = questionData['data'];
        // this.ansModel.attributes.map(v => {
        //   return v.value > 1 ? {...v, 'isVVVVVVVVVVVV': ''} : v 
        // })
        // console.log(this.ansModel.attributes)
        // console.log((questionData['data'][1])?questionData['data'][1]['question_id']:'')
      }
    });
  }
}
