
<!-- <pre *ngIf="model">{{model|json}}</pre> -->
<div *ngIf="model" 
class="container marginT60  marginB30" 
style="max-width:100%;"
>
<div class="col-md-11 ml-md-5">
<div class="card-rounded m-md-5">
  <div class="card-header">
    <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
    <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
    <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
    <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
    <h4 class="mt-1 mb-1" style="text-align: center;">KPI QUESTION (UPDATE & DELETE)</h4>
  </div>
  <div class="custom-control custom-switch pull-right" *ngIf="loggedInRoleId != 2">
    <input type="checkbox" class="custom-control-input" id="switch" [(ngModel)]="kpiOrStakeholderView" (change)="kpiOrStakerholderAction()">
    <label class="custom-control-label" for="switch"><span *ngIf="kpiOrStakeholderView == 1"></span><span *ngIf="kpiOrStakeholderView == 0"></span></label>
    <label class="switch">Switch</label>
  </div>
  <div class="col-7 offset-2" *ngIf="kpiOrStakeholderView == 1">
    <label class="label1">Select KPI</label>
    <h5><select class="form-control"  [(ngModel)]="model.name" (change)="getAllKpiQuestions($event.target.value)" >
      <option value=''>Select option</option>
      <option *ngFor="let xx of kpis" value="{{xx.id}}" >{{xx.title}}</option>
    </select> </h5>
  </div>
  <div class="col-7 offset-2" *ngIf="kpiOrStakeholderView == 0">
    <label class="label1">Select Stakeholder</label>
    <h5><select class="form-control"  [(ngModel)]="model.Stakerholder" (change)="getAllStackholderData($event.target.value)" >
      <option value='' selected>Select Stakeholder</option>
      <option *ngFor="let kld of stackholderListData" value="{{kld.id}}">{{kld.name}}</option>
    </select> </h5>
  </div>
  <div *ngIf="success" class="col-12">
    <div class="form-group">
    <label>Your form has been submitted.</label>
    </div>
  </div>
  <input type="hidden" class="form-control" [(ngModel)]="model.kpiId">
  <form class="ftheme" >
    
  <div *ngIf="!success" class="col-12">
    <div *ngFor="let item of questionData" class="form-group">
      <hr>
      <div *ngIf="item.type=='text'" class="col-12 row">
        <div class="col-8"> 
            <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
              <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
              <div class="input-group-append">
                <button class="btn btn-success" 
                (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
                <i class="fa fa-save"></i></button>
              </div>
            </div>
            <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
            <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
            <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
            <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;"(click)="editKpiQuestions(item.kpi_question_id);" ><i class="fa fa-pencil" ></i></button>
        </div> 
      </div>
      <div *ngIf="item.type == 'group_text'" class="col-12 row">
        <div class="col-8">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);" ><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
      </div>
      <div *ngIf="item.type=='email'" class="col-12 row">
        <div class="col-8"> 
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          </div>
          <div class="col-4"> 
            <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
            <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);"><i class="fa fa-pencil" ></i></button>
            <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
            <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
          </div>
      </div>
      <div *ngIf="item.type=='phone'" class="col-12 row">
        <div class="col-8">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div> 
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;"><i class="fa fa-trash" (click)="deleteQuestion(item.kpi_question_id)"></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" ><i class="fa fa-pencil" (click)="editKpiQuestions(item.kpi_question_id);"></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label> 
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
      </div>
      <div *ngIf="item.type=='number'" class="col-12 row">
        <div class="col-8">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>  
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);" ><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
      </div>
      <div *ngIf="item.type=='date'" class="col-12 row">
        <div class="col-8">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);"><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
     </div>

      <div *ngIf="item.type=='textarea'" class="col-12 row">
        <div class="col-8">
           <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>  
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);" ><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
          </div>
      </div>

      <div *ngIf="item.type=='autocomplete'" class="col-12 row">
        <div class="col-8">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>
            <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
            <select class="form-control autocomplete" id="{{item.name}}" >
              <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>  
            </select>
        </div>
        <div class="col-4">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);"><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
         <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
       <!-- <small class="form-text text-muted">{{item.description}}</small> -->
       </div>
      </div>
      <div *ngIf="item.type=='checkbox'" class="col-12 row">
        <div class="col-6">
           <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>
        
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <div *ngFor="let v of item.values" class="inline-form-group">
            <label class="cust-check-bx">
              <input type="checkbox" [value]="v.value" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-6">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;" (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);"><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
      </div>
      <div *ngIf="item.type=='radio'" class="col-12 row">
        <div class="col-6">
          <div class="input-group mb-3" *ngIf="editMode == item.kpi_question_id">
            <input type="text" class="form-control" [(ngModel)]="item.label" [ngModelOptions]="{standalone: true}" autocomplete="off">
            <div class="input-group-append">
              <button class="btn btn-success" 
              (click)="saveQestions([{'stakeholder_id':item.stakeholder_id,'kpi_question_id':item.kpi_question_id,'label':item.label}]);">
              <i class="fa fa-save"></i></button>
            </div>
          </div>  
          <label *ngIf="editMode != item.kpi_question_id">{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
          <div *ngFor="let v of item.values" class="inline-form-group">
            <label class="cust-check-bx">
              <input type="radio" [value]="v.value" name="{{v.label}}" > {{v.label}}
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="col-6">
          <button *ngIf="item.stakeholder_id == null" class="btn btn-danger" style="float: right;"><i class="fa fa-trash" ></i></button>
          <button class="btn btn-outline-info mr-2" *ngIf="editMode != item.kpi_question_id" style="float: right;" (click)="editKpiQuestions(item.kpi_question_id);" ><i class="fa fa-pencil" ></i></button>
          <!-- <label (click)="deleteQuestion(item.kpi_question_id)"><i class="fa fa-trash-o"></i></label>
          <label (click)="editKpiQuestions(item.kpi_question_id);"  *ngIf="editMode != item.kpi_question_id"><i class="fa fa-pencil"></i></label> -->
        </div>
      </div>
    </div>
  </div>
  </form>
</div>
</div>
</div>



