import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from '../../allServices/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
 
  public loggedInData: any;
  public loading:boolean;
  public loggedInRoleId:string;
  public loggedInUserName:string;
  booleanVal:boolean = false;
  constructor(private ApiService:ApiService,private router: Router) { }
  model:any={
    user_id:'',
    password:'',
    confirm_password:'',
  };
  ngOnInit(): void {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInUserName = this.capitalize((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).name: "Demo");
    this.model.user_id=((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).user_id : 0);
  }
  updatePassword()
  {
    // let params ={"user_id": this.user_id,"password": this.model.password,"confirm_password": this.model.confirm_password,};
    // let params = new HttpParams().set("user_id",this.collectionForm.user_id)
    this.ApiService.create(this.model,'password-reset').subscribe(r=>{
      console.log(r);
      new swal('Success',r['data'],'success');
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
      localStorage.removeItem("farmer_profile_id");
      this.router.navigate(['login']);
    },error=>{
      new swal('Error',error.message,'error');
    });
  } 
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  restPassword()
  {
    this.model.password='';
    this.model.confirm_password='';
  }
  confirm_password(val){
    // console.log(val.length)
    if((this.model.password).length <= (val).length){
      (this.model.password != val) ? this.booleanVal=true : this.booleanVal=false;
    }
  }
}
