<!-- <div class="bg_image "> -->
<div class="container" id="excel-table">
    <div class="card-rounded mt-5">
        <div class="card-header ">
        <!-- <div class="col-9  mt-4 mb-4 block"> -->
         <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
         <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
         <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
         <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
         <h4 class="mt-1 mb-1 an-1"> DASHBOARD</h4>     
        </div>
    
    <div class="mt-md-3 ml-md-5 mr-md-5 a7">
    <div class="card-group mt-2 mb-2 ">
        <div class="container">

            <div class="row mt-4 mb-2">
                
                <div class="col-md-5 ml-auto mr-auto" *ngIf="loggedInRoleId == '1' || loggedInRoleId == '2' || loggedInRoleId == '4'" [routerLink]="(loggedInRoleId == '1' || loggedInRoleId == '4') ? ['/kpi-list'] : []" [style.cursor]="(loggedInRoleId == '1' || loggedInRoleId == '4') ? 'pointer': ''">
                    <div class="card order-card">
                        <div class="card-block">
                            <div class="row"  [routerLink]="(loggedInRoleId == '2') ? ['/kpi-list'] : []"  [style.cursor]="'pointer'">
                                <div class="col-md-4">
                                <img src="assets/images/total_KPI.png" alt="" class=" mt-5 mb-md-5 kpi-icon">
                                </div>
                                <div class="col-md-8">
                                <h5 *ngIf="loggedInRoleId == '1' || loggedInRoleId == '4'" class="ml-2 mb-4 mt-5 mr-auto" >Total KPIs<span class="f-right mr-3">{{dashboardData.total_kpis}}</span></h5>
                                <h5 *ngIf="loggedInRoleId != '4'" [class]="(loggedInRoleId == 1)? 'ml-2 mb-4 mr-auto card2': 'ml-2 mb-4 mt-5 mr-auto'" >Network KPIs<span class="f-right mr-3">{{dashboardData.network_kpis}}</span></h5>
                                <h5 *ngIf="loggedInRoleId != '4'" class="ml-2 mb-4 mr-auto card2" >REC KPIs<span class="f-right mr-3">{{dashboardData.rec_kpis}}</span></h5>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div class="col-md-5 mr-auto" *ngIf="loggedInRoleId == '1' || loggedInRoleId == '2'">
                    <div class="card order-card">
                        <div class="card-block1">
                            <div class="row">
                                <div class="col-md-4">
                                <img src="assets/images/total_Projects.png" alt="" class=" mt-5 mb-md-5 projects-icon">
                                </div>
                                <div class="col-md-8">
                                    <h5 class="ml-2 mb-4 mt-5 mr-auto" [routerLink]="(loggedInRoleId == '1' || loggedInRoleId == '2') ? ['/create-project'] : []" [style.cursor]="(loggedInRoleId == '1' || loggedInRoleId == '2') ? 'pointer': ''">Total Projects<span class="f-right mr-3">{{dashboardData.projects}}</span></h5>
                                    <h5 class="ml-2 mb-4 mr-auto card2" [routerLink]="(loggedInRoleId == '1' || loggedInRoleId == '2') ? ['/forms-list'] : []"  [style.cursor]="'pointer'">Collection Forms<span class="f-right mr-3">{{dashboardData.total_collection_forms}}</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 mr-auto" *ngIf="loggedInRoleId == '4'" [routerLink]="(loggedInRoleId == '4') ? ['/forms-list'] : []" [style.cursor]="(loggedInRoleId == '4') ? 'pointer': ''">
                    <div class="card order-card">
                        <div class="card-block1">
                            <div class="row">
                                <div class="col-md-4">
                                <img src="assets/images/total_Projects.png" alt="" class=" mt-5 mb-md-5 projects-icon">
                                </div>
                                <div class="col-md-8">
                                <h5 class="ml-2 mb-3 pt-4 mt-3 mr-auto">Total Forms<span class="f-right mr-3">{{dashboardData.total_collection_forms}}</span></h5>
                                <h6 class="ml-2 mb-3  mt-3">Accepted Forms<span class="f-right mr-3">{{dashboardData.accepted_forms}}</span></h6>
                                <h6 class="ml-2 mb-3  mt-3">Rejected Forms<span class="f-right mr-3">{{dashboardData.rejected_forms}}</span></h6>
                                <h6 class="ml-2 mb-3  mt-3">Pending Forms<span class="f-right mr-3">{{dashboardData.pending_forms}}</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            <div class="col-md-5 ml-auto mr-auto" *ngIf="loggedInRoleId == '1'" [routerLink]="loggedInRoleId == '1' ? ['/manager-list'] : []" [style.cursor]="loggedInRoleId == '1' ? 'pointer': ''">
                    <div class="card order-card">
                        <div class="card-block1">
                            <div class="row">
                                <div class="col-md-4">
                                <img src="assets/images/managers.png" alt="" class=" mt-5 mb-md-5 manager-icon">
                                </div>
                                <div class="col-md-8">
                                    <h5 class="ml-2 mb-4 mt-5 mr-auto"> Project Managers<span class="f-right mr-3">{{dashboardData.project_managers}}</span></h5>   
                                    <h5 class="ml-2 mb-4 mr-auto">Data Collectors<span class="f-right mr-3">{{dashboardData.data_collectors}}</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 ml-auto mr-auto" *ngIf="loggedInRoleId == '4'" [routerLink]="loggedInRoleId == '4' ? ['/data-collector'] : []" [style.cursor]="loggedInRoleId == '4' ? 'pointer': ''">
                    <div class="card order-card">
                        <div class="card-block1">
                            <div class="row">
                                <div class="col-md-4">
                                <img src="assets/images/managers.png" alt="" class=" mt-5 mb-md-5 manager-icon">
                                </div>
                                <div class="col-md-8">
                                    <h5 class="ml-2 mb-2 pt-4 mt-3 mr-auto"> Data Collector<span class="f-right mr-3">{{dashboardData.data_collectors}}</span></h5>   
                                    <h6 class="ml-2 mb-2 mt-3 "> Active Collector<span class="f-right mr-3">{{dashboardData.data_collectors_active}}</span></h6> 
                                    <h6 class="ml-2 mb-2 mt-3 "> Inactive Collector<span class="f-right mr-3">{{dashboardData.data_collectors_inactive}}</span></h6>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mr-auto" *ngIf="loggedInRoleId == '1' || loggedInRoleId == '4'" [routerLink]="loggedInRoleId == '1' ? ['/export-data'] : ['/get-answers']" [style.cursor]="(loggedInRoleId == '1' || loggedInRoleId == '4') ? 'pointer': ''">
                    <div class="card order-card">
                        <div class="card-block">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/data_collecter.png" alt="" class=" mt-5 mb-md-5 data-collector-icon">
                                </div>
                                <div class="col-md-8">
                                <h5 class="ml-2 mb-4 mt-5 mr-auto">Ongoing Data<span class="f-right mr-3 ">{{dashboardData.ongoing_collection}}</span></h5>
                                <h5 class="ml-2 mb-4 mr-auto">Collected Data<span class="f-right mr-3">{{dashboardData.collection_completed}}</span></h5>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>
</div>
<!-- <div class="">
        <div class="col-10 offset-1 mt-5 mb-5 block">
            <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
             <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
             <h4 class="mt-1"> DASHBOARD</h4>
             
        </div>
        
        <div class="col-10 offset-1 container">
        
      <div class="">

        <div class="row col-12">
            
            <div class="col-3 " [routerLink]="loggedInRoleId == '1' ? ['/kpi-list'] : []" [style.cursor]="loggedInRoleId == '1' ? 'pointer': ''">
                <div class="card order-card">
                    <div class="card-block mt-5 mb-5 ml-2">
                            
                               <img src="assets/images/home/kpi_icon.png" alt="" class="ml-5 pl-4 mt-5 mb-5">
                            
                                <div class="col-12 " style="background-color: #3d3d3d; border-radius: 10px; color: white;">
                                   <h6 class="m-3  ">Total KPIs<span class="f-right mr-1">{{dashboardData.kpis}}</span></h6>
                                   <h6 class=" m-3 ">Question Added KPIs<span class="f-right mr-1">{{dashboardData.added_questions}}</span></h6>
                                </div>
                    </div>
                </div>
            </div>
            
            <div class="col-3 " [routerLink]="loggedInRoleId == '1' ? ['/create-project'] : []" [style.cursor]="loggedInRoleId == '1' ? 'pointer': ''">
                <div class="card order-card">
                    <div class="card-block mt-5 mb-5 ml-2">
                        
                            <img src="assets/images/home/Projects_icon.png" alt="" class="ml-5 pl-4 mt-5 mb-5 ">
                           
                            <div class="col-12 " style="background-color: #FFC416; border-radius: 10px;">
                               <h6 class=" m-3 mb-5">Total Projects<span class="f-right mr-1">{{dashboardData.projects}}</span></h6>
                            </div>
                           
                    </div>
                </div>
            </div>
            
        <div class="col-3"  [routerLink]="loggedInRoleId == '1' ? ['/manager-list'] : []" [style.cursor]="loggedInRoleId == '1' ? 'pointer': ''">
                <div class="card order-card">
                    <div class="card-block mt-5 mb-5 ml-2">
                       
                            <img src="assets/images/home/Managers_icon.png" alt="" class="ml-5 pl-4 mt-5 mb-5">
                            
                            <div class="col-12" style="background-color: #3d3d3d; border-radius: 10px;color: white;">
                                <h6 class=" m-3 mb-5"> Project Managers<span class="f-right mr-1">{{dashboardData.project_managers}}</span></h6>   
                            </div>
                    </div>
                </div>
            </div>
           
            <div class="col-3 " [routerLink]="loggedInRoleId == '1' ? ['/export-data'] : []" [style.cursor]="loggedInRoleId == '1' ? 'pointer': ''">
                <div class="card order-card">
                    <div class="card-block mt-5 mb-5 ml-2">
                    
                            <img src="assets/images/home/Data_collector.png" alt="" class="ml-5 pl-4 mt-5 mb-5">
                            <div class="col-12" style="background-color: #FFC416; border-radius: 10px;">
                               <h6 class="m-3">Initiated Data<span class="f-right mr-1 ">{{dashboardData.farmer_profiles}}</span></h6>
                               <h6 class="m-3 ">Collected Data<span class="f-right mr-1">{{dashboardData.data_collectors}}</span></h6>
                            </div>
                          
                    </div>
                </div>
            </div>
  </div>
  </div>
</div> -->