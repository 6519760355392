import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, tap,retry } from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';


const API_URL = environment.hostUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient)  { }

  get(URI){
    return this.http.get<any[]>(`${API_URL}/${URI}`);
  }
  getByParams(URI,params:any){
    // console.log(params)
    return this.http.get<any[]>(`${API_URL}/${URI}`,params);
  }
  create(model: any, URI): Observable<Response> {
    // console.log(model)
    return this.http.post<Response>(`${API_URL}/${URI}`, model);
  }
  getById(id:number, URI){
    return this.http.get<any[]>(`${API_URL}/${URI}/${id}`);
  }
  delete(URI){
    return this.http.delete(`${API_URL}/${URI}`);
  }
  put(model: any, URI): Observable<Response> {
    return this.http.put<Response>(`${API_URL}/${URI}`, model);
  }

  getExcel(uri,params:any): Observable<Blob> {
    // console.log(uri)
    //const options = { responseType: 'blob' }; there is no use of this
   // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
    return this.http.get(`${API_URL}/${uri}`,{responseType: 'blob'});
  }
  getExcelWithParams(URI,params:any){
    // console.log(params)
    return this.http.post<any[]>(`${API_URL}/${URI}`, params, {observe: 'response', responseType: 'blob' as 'json'});
  }
  getIPAddress(URI)  
  {  
    return this.http.get<{ip:string}>(`${URI}`);  
  }  

}
