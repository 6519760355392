import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule} from '@angular/forms';

const material = [
  BrowserAnimationsModule,
  ReactiveFormsModule
];

@NgModule({
  imports: [material],
  exports: [material]
})
export class MaterialModule { }
