import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class DataCollectorGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

      let currentUser = JSON.parse(localStorage.getItem('user_data'));
      if(currentUser == null){
        this.router.navigate(['/login']);
        return false;
      }
      if (currentUser.role_id == '5') {
          return true;
      } else {
        this.router.navigate(['/login'], {
          queryParams: {
            return: state.url
          }
        });

      // this.router.navigate(['/data-collection']);
      return false;
      }
  }
}
