import { Component, OnInit } from '@angular/core';
import { DndDropEvent,DropEffect} from 'ngx-drag-drop';
import { field, value } from '../../global.model';
import { ActivatedRoute } from '@angular/router';
// import swal from 'sweetalert2';
import swal from 'sweetalert2/dist/sweetalert2.js';  
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from '../../allServices/api.service';
import { UriList } from '../../uri.list';
import { HttpParams } from '@angular/common/http';
import { analyzeAndValidateNgModules } from '@angular/compiler';


const show: number=1;
@Component({
  selector: 'app-create-questions',
  templateUrl: './create-questions.component.html',
  styleUrls: ['./create-questions.component.css']
})
export class CreateQuestionsComponent implements OnInit {

  skipQuestion:any=[];
  groupQuestion:any=[];
  kpis:any = [];
  kpiListData:any;
  kpiOrStackholderView: number=1;
  value:value={
    label:"",
    value:""
  };
  success = false;
  loggedInData: string;
  loggedInRoleId:string;
  fieldModels:Array<field>=[
    {
      "type": "text",
      "icon": "fa-font",
      "label": "Text",
      "description": "Enter your name",
      "placeholder": "",
      "className": "form-control",
      "subtype": "text",
      "is_integer": 1,
      "is_double": 0,
      "regex" : "",
      "handle":true,
      "required": false,
      "is_jump": 0,
      "values": [],
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "email",
      "icon": "fa-envelope",
      "required": true,
      "label": "Email",
      "description": "Enter your email",
      "placeholder": "",
      "className": "form-control",
      "subtype": "text",
      "regex" : "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,5})$",
      "errorText": "Please enter a valid email",
      "is_integer": 0,
      "is_double": 0,
      "handle":true,
      "is_jump": 0,
      "values": [],
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "phone",
      "icon": "fa-phone",
      "required": false,
      "label": "Phone",
      "description": "Enter your phone",
      "placeholder": "",
      "className": "form-control",
      "subtype": "text",
      "regex" : "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
      "errorText": "Please enter a valid phone number",
      "is_integer": 1,
      "is_double": 0,
      "handle":true,
      "is_jump": 0,
      "values": [],
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "number",
      "label": "Number",
      "icon": "fa-html5",
      "description": "Age",
      "placeholder": "",
      "className": "form-control",
      "subtype": "text",
      "regex": "",
      "value": "20",
      "min": 0,
      "max": 0,
      "is_integer": 1,
      "is_double": 0,
      "is_jump": 0,
      "values": [],
      "handle": true,
      "required": false,
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "date",
      "icon":"fa-calendar",
      "label": "Date",
      "placeholder": "Date",
      "className": "form-control",
      "subtype": "date",
      "regex": "",
      "is_integer": 1,
      "is_double": 0,
      "is_jump": 0,
      "values": [],
      "handle": true,
      "required": false,
      "description": "date",
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "datetime-local",
      "icon":"fa-calendar",
      "label": "DateTime",
      "placeholder": "Date Time",
      "subtype": "datetime",
      "regex": "",
      "is_integer": 1,
      "is_double": 0,
      "className": "form-control",
      "is_jump": 0,
      "values": [],
      "handle": true,
      "required": false,
      "description": "datetime",
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "textarea",
      "icon":"fa-text-width",
      "label": "Textarea",
      "subtype": "textarea",
      "regex": "",
      "is_integer": 1,
      "is_double": 0,
      "is_jump": 0,
      "handle": true,
      "values": [],
      "required": false,
      "description": "textarea",
      "placeholder":"",
      "className":"",
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "checkbox",
      "required": true,
      "label": "Multi(Checkbox)",
      "subtype": "checkbox",
      "regex": "",
      "placeholder":"",
      "icon":"fas fa-check-square",
      "description": "Checkbox",
      "className": "form-control",
      "is_integer": 1,
      "is_double": 0,
      "inline": true,
      "is_jump": 0,
      "handle": true,
      "values": [
        {
          "label": "Option 1",
          "value": "1"
        },
        {
          "label": "Option 2",
          "value": "2"
        }
      ],
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "autocomplete",
      "icon":"fas fa-chevron-circle-down",
      "label": "Drop down",
      "is_integer": 0,
      "is_double": 0,
      "is_jump": 0,
      "required": false,
      "subtype": "select",
      "regex": "",
      "description": "Select",
      "placeholder": "Select",
      "className": "form-control",
      "handle": true,
      "values": [
        {
          "label": "Option 1",
          "value": "1"
        },
        {
          "label": "Option 2",
          "value": "2"
        },
        {
          "label": "Option 3",
          "value": "3"
        }
      ],
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "radio",
      "icon":"fa-list-ul",
      "label": "Single(Radio)",
      "subtype": "Radio",
      "regex": "",
      "is_integer": 1,
      "is_double": 0,
      "is_jump": 0,
      "description": "Radio boxes",
      "className": "form-control",
      "placeholder": "",
      "values": [
        {
          "label": "Option 1",
          "value": "1"
        },
        {
          "label": "Option 2",
          "value": "2"
        }
      ],
      "handle": true,
      "required": false,
      "jump_value":"",
      "jump_reference":"",
      "is_group":0,
      "is_group_onOff":false,
      "group_reference":"",
      "linked":0
    },
    {
      "type": "group_text",
      "icon": "fa-object-group",
      "label": "Group Text",
      "description": "group_text",
      "placeholder": "",
      "className": "form-control",
      "subtype": "group_text",
      "is_integer": 1,
      "is_double": 0,
      "regex" : "",
      "handle":true,
      "required": false,
      "is_jump": 0,
      "values": [],
      "jump_value":"",
      "jump_reference":"",
      "is_group":1,
      "is_group_onOff":true,
      "group_reference":"",
      "linked":0
    }
  ];

  modelFields:Array<field>=[];
  model:any = {
    kpi_id: 0,
    stakeholder_id: 0,
    theme:{
      bgColor:"ffffff",
      textColor:"000000",
      bannerImage:""
    },
    attributes:this.modelFields
  };
  

  report = false;
  reports:any = [];

  constructor(
    private route:ActivatedRoute,
    private ApiService:ApiService
  ) { }

  ngOnInit() {
    this.loggedInData = localStorage.getItem('user_data');
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0)
  
    this.loadAllKpis();
    this.getKpiListData();
  }

  ngDoCheck() {
    let dropId = this.model.name;
    // this.model.kpi_id = dropId.substring(dropId.indexOf('$$')+2);
    this.skipQuestion = this.model.attributes;
    this.groupQuestion = this.model.attributes;
   
  }
  jumpReference(val){
    this.model.attributes = this.model.attributes.map(obj1 =>
      obj1.name == val ? { ...obj1, is_jump: 2 } : obj1
    );
  }
  selectedGroupQuestion(name){
    this.model.attributes = this.model.attributes.map(obj1 =>
      obj1.name == name ? { ...obj1, is_group: 1, linked: 0 } : obj1
    );
    this.model.attributes = this.model.attributes.map(obj2 =>
      obj2.group_reference == name ? { ...obj2, is_group: 0, linked: 1, type:'group_text' } : obj2
    );
    // console.log(this.model.attributes)
  }
  private async loadAllKpis() {
    let URI =`${UriList.GETKPISCREATEDBYREC}`;
    // let params = new HttpParams().set("region_id",JSON.parse(this.loggedInData).region_id)
    return await this.ApiService.getById(JSON.parse(this.loggedInData).region_id,URI).pipe(first()).subscribe(kpis => {
      this.kpis=kpis['data'];
      // console.log(kpis['data'])
    });
  }

  onDragStart(event:DragEvent) {
    // console.log("drag started", JSON.stringify(event, null, 2));
  }
  
  onDragEnd(event:DragEvent) {
    // console.log("drag ended", JSON.stringify(event, null, 2));
  }
  
  onDraggableCopied(event:DragEvent) {
    console.log("draggable copied", JSON.stringify(event, null, 2));
  }
  
  onDraggableLinked(event:DragEvent) {
    console.log("draggable linked", JSON.stringify(event, null, 2));
  }
    
   onDragged( item:any, list:any[], effect:DropEffect ) {
    if( effect === "move" ) {
      const index = list.indexOf( item );
      list.splice( index, 1 );
    }
  }
      
  onDragCanceled(event:DragEvent) {
    // console.log("drag cancelled", JSON.stringify(event, null, 2));
  }
  
  onDragover(event:DragEvent) {
    // console.log("dragover", JSON.stringify(event, null, 2));
  }
  onDrop( event:DndDropEvent, list?:any[] ) {
    if( list && (event.dropEffect === "copy" || event.dropEffect === "move") ) {
      
      if(event.dropEffect === "copy")
      event.data.name = event.data.type+'-'+new Date().getTime();
      let index = event.index;
      if( typeof index === "undefined" ) {
        index = list.length;
      }
      list.splice( index, 0, event.data );
    }
  }
  addValue(values){
    values.push(this.value);
    this.value={label:"",value:""};
  }
  removeField(i,val){
    new swal({
      title: 'Are you sure?',
      text: "Do you want to remove this field?",
      showCancelButton: true,
      confirmButtonColor: '#00B96F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove!'
    }).then((result) => {
      if (result.value) {
    
        this.model.attributes.splice(i,1);
        this.model.attributes = this.model.attributes.map(removeObj =>
          (removeObj.is_group == 1) ? { ...removeObj, is_group: 0, linked: 0 } : removeObj
        );
        this.model.attributes = this.model.attributes.map(jumpObj =>
          jumpObj.name == val ? { ...jumpObj, is_jump: 0 } : jumpObj
          // {
          //   console.log(val)
          //   console.log(i)
          //   return 0;
          // }
        );
        console.log(this.model.attributes)
      }
    });
  }
  initReport(){
    this.report = true; 
    let input = {
      id:this.model._id
    }
  }
  toggleValue(item){
    item.selected = !item.selected;
  }
  updateForm(){
    let input = new FormData;
    if(Object.keys(this.model.attributes).length === 0){
      new swal('Error','Plese enter any valid fields');
      return false;
    }
    // console.info(this.model)
    // if((this.model.kpi_id == 0) || (this.model.stakeholder_id == 0)){
    //   new swal('Error','Please select valid field');
    //   return false;
    // }
    input.append('id',this.model._id);
    input.append('name',this.model.name);
    input.append('description',this.model.description);
    input.append('bannerImage',this.model.theme.bannerImage);
    input.append('bgColor',this.model.theme.bgColor);
    input.append('textColor',this.model.theme.textColor);
    input.append('attributes',JSON.stringify(this.model.attributes));
    // console.log(this.model);
    // return 0
    this.ApiService.create(this.model,`${UriList.QUESTION}`).subscribe(r=>{
      // console.log(r);
      this.model.attributes=[];
      this.model.name = "select options";
      new swal('Success','You have contact sucessfully','success');
      this.success = true;
    },error=>{
      new swal('Error',error.message,'error');
    });
  }
 
  reset(){
    this.model.attributes=[];
  }
  async getKpiListData()
  {
    let URI =`${UriList.GETSTAKEHOLDER}`;
    return await this.ApiService.get(URI).pipe(first()).subscribe(val => {
      this.kpiListData=val['data'];
      // console.log(val['data'][0])
    });
  }
  public kpiOrStakerholderAction()
  {
    this.model.kpi_id=0;
    this.model.stakeholder_id=0;
  }


  public textBold(id){
    if(document.getElementById(id).style.fontWeight == '600'){
      document.getElementById(id).style.fontWeight = '100'
    }
    else{
      document.getElementById(id).style.fontWeight = '600';
    }
  }

  public textItalic(id){
    if(document.getElementById(id).style.fontStyle == 'italic'){
      document.getElementById(id).style.fontStyle = 'normal'
    }
    else{
      document.getElementById(id).style.fontStyle = 'italic';
    }
  }

  public textUnderline(id){
    if(document.getElementById(id).style.textDecoration=='underline'){
      document.getElementById(id).style.textDecoration = ''
    }
    else{
      document.getElementById(id).style.textDecoration = 'underline';
    }
  }

  public textLink(id){
    if(document.getElementById(id).style.textDecoration=='underline'){
      document.getElementById(id).style.textDecoration = ''
      document.getElementById(id).style.color="";
      document.getElementById(id).style.cursor="";
    }
    else{
      document.getElementById(id).style.textDecoration = 'underline';
      document.getElementById(id).style.color="blue";
      document.getElementById(id).style.cursor="pointer";
    }
  }
  
  // public textshadow(id){
  //   if(document.getElementById(id).style.textShadow="5px 5px blue"){
  //     document.getElementById(id).style.textShadow='normal'
  //   }
  //   else{
  //     document.getElementById(id).style.textShadow="5px 5px blue"
  //   }
  // }
}