<div class="container">
  <div class="card-rounded mt-5 mb-5">
  <div class="col-12 mt-5 ml-0 block">
    <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
    <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
    <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
    <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
     <h4 style="text-align: center;" class="mt-1">DATA COLLECTION SUMMARY  </h4> 

  </div>
    <div class="col-md-12  row  mb-5"> 
      <div class="col-md-8 mt-5 mb-5">
        <select class="form-control" [(ngModel)]="model.project_form_id" >
          <option value="" selected>Select Form</option>
          <option *ngFor="let x of projectsFormData" value="{{x.project_form_id}}">{{x.project_form_id}}</option>
        </select>
      </div>
      <!-- <div class="col-md-4 mt-5 mb-5">
        <select class="form-control" [(ngModel)]="model.user_id" >
          <option value="" selected>Select collector</option>
          <option *ngFor="let x of collectors" value="{{x.user_id}}">{{x.name}}</option>
        </select>
      </div> -->
      <div class="col-md-4 mt-5 ">
        <button type="submit" (click)="serachResult()" value="Submit" class="btn-primary">SEARCH</button> &nbsp;
      </div>
        <!-- <p  style="margin-left: 30px;" *ngIf="collectedData">Collected Data: {{collectedData}}</p> -->
    </div>
   
    <div class="col-12 mt-5" >
    <div id="accordion" class="mb-4" *ngIf="(collectedData?.length) > 0">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-outline-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Collected Data
            </button>
          </h5>
        </div>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card">
            <div *ngIf="(collectedData?.length) == 0">
              No Data found
            </div>
            <div  *ngFor="let val of collectedData">
              <div *ngIf="(collectedData?.length) > 0"  class="ml-5 mb-5">
                <p><b style="color: rgb(200, 224, 200);" >Question:</b> {{val.question_label}}</p>
                <br /> 
                <h6 style="color: rgb(129, 125, 121);">Answer: {{val.answer}} </h6>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


    <!-- <div class="col-12 mt-5" *ngFor="let valData of serachResultData | paginate: { itemsPerPage: 1, currentPage: p }">
      <div id="accordion" class="mb-4">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-outline-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Profile Data
              </button>
            </h5>
          </div>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card">
              <div *ngIf="(valData['answer_data']?.length) == 0">
                No Data found
              </div>
              <div  *ngFor="let val of valData['stakeholder_data']">
                <div *ngIf="(valData['answer_data']?.length) > 0">
                  <p *ngIf="val.group_answer == ''"><b>Question:</b> {{val.question}}</p>
                  <p *ngIf="val.group_answer"><b style="color: rgb(200, 224, 200);" >Question:</b> {{val.group_question}}</p>
                  <br /> 
                  <h6 style="color: rgb(129, 125, 121);">Answer: {{val.answer}} <i *ngIf="val.group_answer"> - {{val.group_answer}}</i></h6>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-outline-link collapsed" [disabled]="questionsTab" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Questions Data
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <div *ngIf="(valData['answer_data']?.length) == 0">
                No Data found
              </div>
              <div  *ngFor="let val of valData['answer_data']">
                <div *ngIf="(valData['answer_data']?.length) > 0">
                  <p *ngIf="val.group_answer == ''"><b>Question:</b> {{val.question}}</p>
                  <p *ngIf="val.group_answer"><b style="color: rgb(200, 224, 200);" >Question:</b> {{val.group_question}}</p>
                  <br /> 
                  <h6 style="color: rgb(129, 125, 121);">Answer: {{val.answer}} <i *ngIf="val.group_answer"> - {{val.group_answer}}</i></h6>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
  </div> -->
</div>  
</div>
