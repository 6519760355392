import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../allServices/api.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2/dist/sweetalert2.js'; 
import {ToastrService} from 'ngx-toastr';
import { UriList } from '../../uri.list';
import { fader, slider } from '../../route-animations'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group
} from '@angular/animations';

@Component({
  selector: 'app-data-collector',
  templateUrl: './data-collector.component.html',
  styleUrls: ['./data-collector.component.css'],
  animations: [ fader,
    trigger('flyInOut', [
      state('in', style({
        width: 120,
        transform: 'translateX(0)', opacity: 1
      })),
      transition('void => *', [
        style({ width: 10, transform: 'translateX(50px)', opacity: 0 }),
        group([
          animate('3s 0.1s ease', style({
            transform: 'translateX(0)',
            width: 120
          })),
          animate('0.3s ease', style({
            opacity: 1
          }))
        ])
      ]),
      transition('* => void', [
        group([
          animate('0.3s ease', style({
            transform: 'translateX(50px)',
            width: 10
          })),
          animate('0.3s 0.2s ease', style({
            opacity: 0
          }))
        ])
      ])
    ]) ],
})
export class DataCollectorComponent implements OnInit {
  dataCollector:any=[];
  name:string;
  projectsData:any;
  project_id: number = 0;
  filterTerm:string;
  constructor(private ApiService:ApiService,private toastr:ToastrService) { }

  ngOnInit() {
    this.getData();
    this.getProjectData();
    this.projectData.manager_id = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    this.userModel.created_by = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
  }
  userModel:any = {
    name:"",
    email:"",
    password:"",
    mobile:"",
    role_id:5,
    created_by:''
  }
  projectData:any={
    data_collector_id: '',
    project_id:'',
    manager_id:'',
    target: '',
    project_form_id:''
  }
  getData()
  {
    let managerId = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    let URI =`${UriList.GETDATACOLLECTOR}/${managerId}`;
    // console.log(URI)
    return this.ApiService.get(URI).pipe(first()).subscribe(r => {
      // console.log(r['data'])
      this.dataCollector=r['data'];
    });
  }

  private getProjectData()
  {
    let URI =`${UriList.GETPROJECTBYCOLLECTIONFORM}`;
    return this.ApiService.get(URI).pipe(first()).subscribe(r => {
      this.projectsData=r['data'];
        // console.log(r['data'])
    });
  }
  saveDataCollector(){
    let URI =`${UriList.REGISTER}`;
    // console.log(this.userModel)
    // return 0;
    return this.ApiService.create(this.userModel,URI).pipe(first()).subscribe(r => {
      this.userModel = {};
      document.getElementById("dataCollector").click();
      new swal('Success','You have created sucessfully','success');
      this.getData();
      // console.log(r['data'])
    },err => {
      this.toastr.error('everything is broken','Please enter valid input', {
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
      });
      // console.log(err.error.errors[0]);
    });
  }
  deleteDataCollector(id,target){
    if(target == null){
      new swal({
        title: 'Are you sure?',
        text: "Do you want to remove?",
        showCancelButton: true,
        confirmButtonColor: '#00B96F',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!'
      }).then((result) => {
        if(result.value){
          this.toastr.success('Deleted Successfully', 'Success!', {
            positionClass: 'toast-bottom-right',
            timeOut: 5000
          });
          return this.ApiService.delete(`${UriList.URERDELETE}/${id}`).pipe(first()).subscribe(r =>{
              console.log(r['data'])
              this.getData();
            })
        }
  
      });   
    }
   
  }
  shareProject(id:any){
    // console.log(id)
    this.projectData.data_collector_id = id;
    this.projectData.manager_id = (localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).user_id : 0;
    if(this.projectData.project_id){
      // console.log(this.projectData)
      // return 0
      let URI=`${UriList.STORESHAREDPROJECT}`;
      return this.ApiService.create(this.projectData,URI).pipe(first()).subscribe(r => {
        // console.log(r['data']);
        this.projectData = {};
        document.getElementById("shareProject").click();
        new swal('Success','You have shared sucessfully','success');
      });
    }
  }

  getProjectId(id){
    // this.projectData = {};
    this.projectsData.map(v => {
        if(v.project_id = id){
          this.projectData.project_form_id = v.project_form_id;
        }
      })
  }
  private randomstring(L) {
    var s = '';
    var randomchar = ()=> {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L) s += randomchar();
    return s;
  }

}
