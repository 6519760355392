<!-- <div class="bg_image "> -->
    <div class="container" id="excel-table">
        <div class="card-rounded mt-5">
            <div class="card-header ">
            <!-- <div class="col-9  mt-4 mb-4 block"> -->
             <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
             <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
             <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
             <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
             <h4 class="mt-1 mb-1">DATA COLLECTOR DASHBOARD</h4>     
            </div>
        
        <div class="mt-md-3 ml-md-5 mr-md-5 a7">
        <div class="card-group mt-2 mb-2 ">
            <div class="container">
    
                <div class="row mt-4 mb-2">
                    
                        
                    <div class="col-md-5 mr-auto" *ngIf="loggedInRoleId == '5' || loggedInRoleId == '2'">
                        <div class="card order-card">
                            <div class="card-block1">
                                <div class="row">
                                    <div class="col-md-4">
                                    <img src="assets/images/total_Projects.png" alt="" class=" mt-5 mb-md-5 projects-icon">
                                    </div>
                                    <div class="col-md-8">
                                        <h5 class="ml-2 mb-4 mt-5 mr-auto">Target Data<span class="f-right mr-3">{{dashboardData.target_assigned}}</span></h5>
                                        <h5 class="ml-2 mb-4 mr-auto card2" >Achieved Data<span class="f-right mr-3">{{dashboardData.target_achieved}}</span></h5>
                                        <h5 class="ml-2 mb-4 mr-auto card2" >Pending Data<span class="f-right mr-3">{{((dashboardData.target_assigned)-(dashboardData.target_achieved))}}</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-5 mr-auto" *ngIf="loggedInRoleId == '5'">
                        <div class="card order-card">
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="assets/images/data_collecter.png" alt="" class=" mt-5 mb-md-5 data-collector-icon">
                                    </div>
                                    <div class="col-md-8">
                                    <h5 class="ml-2 mb-4 mt-5 mr-auto">Ongoing Data<span class="f-right mr-3 ">{{dashboardData.ongoing_collection}}</span></h5>
                                    <h5 class="ml-2 mb-4 mr-auto">Collected Data<span class="f-right mr-3">{{dashboardData.collection_completed}}</span></h5>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </div>
    </div>
    </div>
