<div class="container dataCollector">
  <div class="card-rounded mt-5 mb-5 ">
    <div class="card-header card_header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center; ">DATA COLLECTION</h4>
    </div>
      <!-- <div class="select_project col-8 mt-4 mb-5">
        <label>Select Project <i style="color: red;">*</i></label>
        <h5><select class="form-control" (change)="getFormByProjectId($event.target.value)">
          <option value="" selected disabled>Select project</option>
          <option *ngFor="let x of projectsData" value="{{x.id}}" >{{x.name}}</option>
        </select> </h5>
      </div> -->
      <div class="col-12 bg-c-grey row ml-0"> 
        <div class="col-8 offset-2 mt-4">
          <label>Select Form <i style="color: red;">*</i></label>
          <select class="form-control" (change)="getFormByProjectId($event.target.value)" >
            <option value="" selected>Select Form</option>
            <option *ngFor="let x of projectsData" value="{{x.project_form_id}}">{{x.project_form_id}}</option>
          </select>
        </div>
      <div class="col-12 mt-5">
      <div id="accordion" class="mb-4">
        <div class="card" *ngIf="itemButton == 'button'">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-outline-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
               # Questions <span *ngIf="projectName">( {{projectName}} )</span>
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <!-- <pre *ngIf="model">{{model|json}}</pre> -->
              <div class="container mt-4 mb-5">
                <div class="col-10 offset-1 mt-4 mbHeight">
                  <form class="ftheme" >
                  
                    <div *ngIf="!success" class="col-12">
                     
                      <div *ngFor="let item of questionData" class="form-group">
                        <div *ngIf="item.type=='text'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger" [(ngModel)]="ansModel.value">*</label>
                          <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [required]="item.required" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type == 'group_text'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.group_value" [required]="item.required" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='email'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='phone'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='number'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}" max="{{item.max}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                          </div>
                        <div *ngIf="item.type=='date'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="date" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='datetime-local'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="datetime-local" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                        </div>
                        <div *ngIf="item.type=='textarea'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </textarea>
                          </div>
                        <div *ngIf="item.type=='file'">
                          <label>{{item.label}} (File can't be uploaded right now)</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <input type="file" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}" autocomplete="off">
                          <small class="form-text text-danger">(File can't be uploaded right now)</small>
                        </div>
                        <div *ngIf="item.type=='paragraph'">
                          <p class="form-text">{{item.placeholder}}</p>
                        </div>
                        <div *ngIf="item.type=='autocomplete'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <select class="form-control" id="{{item.name}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let v of item.values" [value]="v.label">{{v.label}}</option>  
                          </select>
                          <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                        </div>
                        <div *ngIf="item.type=='checkbox'">
                          <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                          <div *ngFor="let v of item.values" class="inline-form-group">
                              <label class="cust-check-bx">
                                <input type="checkbox" [value]="v.label" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
                                <span class="checkmark"></span>
                              </label>
                            </div>
                        </div>
                        <div *ngIf="item.type=='radio'">
                            <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                            <div *ngFor="let v of item.values" class="inline-form-group">
                                <label class="cust-check-bx">
                                  <input type="radio" [value]="v.label" name="{{item.name}}" [(ngModel)]="ansModel.value" [ngModelOptions]="{standalone: true}"> {{v.label}}
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                          </div>
                      </div>
              
                    </div>
                    </form>
                  </div>
                  <h5 class="nextPrevious">{{currentIndex+1}}/{{questionsLength}}</h5><hr />
                  <button *ngIf="currentIndex > 0" class="btn btn-outline-primary previous" value="Previous" disabled (click)="previousQuestion()"> <i class="fa fa-backward" aria-hidden="true"></i> </button>
                  <button *ngIf="(currentIndex+1) != questions.length && ansModel.stakeholder_id == 0" class="btn btn-outline-primary pull-right"  value="Next" (click)="getQuestionStoreAnswer()"> <i class="fa fa-forward" aria-hidden="true"></i> </button>
                  <button *ngIf="(currentIndex+1) != questions.length && ansModel.stakeholder_id != 0 && ansModel.stakeholder_id != 8" class="btn btn-outline-primary pull-right"  value="Next" (click)="getProfileStoreAnswer()"> <i class="fa fa-forward" aria-hidden="true"></i><i class="fa fa-forward" aria-hidden="true"></i> </button>
                  <button *ngIf="(currentIndex+1) != questions.length && ansModel.stakeholder_id == 8" class="btn btn-outline-primary pull-right"  value="Next" (click)="getQuestionStoreAnswer()"> <i class="fa fa-forward" aria-hidden="true"></i> </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>