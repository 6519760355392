<div *ngIf="loggedInRoleId != 0">
  <div class="profileDiv">
    <a class="logo">PMEL</a>
    <div class=" role"> | {{loggedInUserName}}</div> 
    <img src="assets/images/solidaridad_logo.png" alt="solidaridad" class="image_logo" [@stagger] *ngIf='show'>
    <!-- <div class="container">
      <div class="scrolling-text" [@scroll]="state" (@scroll.done)="scrollDone()">Hover to pause!</div>
    </div> -->
  </div>
  <div class="m-0">
    <nav class="navbar navbar-expand-sm navbar-light p-0" (click)="onClickNavbarChange($event)">
        <button class="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

          <ul class="navbar-nav mr-auto mt-lg-0">
            <li class="nav-item" *ngIf="loggedInRoleId == 5" >
              <a class="nav-link " routerLink="/collector-dashboard" routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"><img src="assets/images/home/Home_Page.png" alt="" class="mr-2"><b>HOME</b></a>
            </li> 
            <li class="nav-item" *ngIf="loggedInUserName == 'Demo' || loggedInRoleId == 1 || loggedInRoleId == 2 || loggedInRoleId == 4" >
              <a class="nav-link " routerLink="/" routerLinkActive="active" 
              [routerLinkActiveOptions]="{exact:true}"><img src="assets/images/home/Home_Page.png" alt="" class="mr-2"><b>HOME</b></a>
            </li>
            <li class="nav-item"  *ngIf="loggedInRoleId == 4">
              <a class="nav-link" routerLink="/data-collector" routerLinkActive="active"><img src="assets/images/project_manager/data_collector.png" alt="" class="mr-2"><b> COLLECTOR</b></a>
            </li>  
            <li class="nav-item" *ngIf="loggedInRoleId == 4">
              <a class="nav-link" routerLink="/get-answers" routerLinkActive="active"><img src="assets/images/project_manager/data_coll_summary.png" alt="" class="mr-2"><b>DATA COLLECTION SUMMARY</b></a>
            </li>   
            <li class="nav-item" *ngIf="loggedInRoleId == 5">
              <!-- <a routerLink="/data-collection" class="nav-link" routerLinkActive="active"><img src="assets/images/home/data_collection.png" alt="" class="mr-2"><b>DATA COLLECTION</b></a> -->
              <a routerLink="/get-data-collection" class="nav-link" routerLinkActive="active"><img src="assets/images/home/data_collection.png" alt="" class="mr-2"><b>DATA COLLECTION</b></a>
            </li>
            <li class="nav-item"  *ngIf="loggedInRoleId == 1 || loggedInRoleId == 2">
              <a routerLink="/kpi-list" class="nav-link" routerLinkActive="active"><img src="assets/images/home/kpi_list.png" alt="" class="mr-2"><b>KPI LIST</b></a>
            </li>
            <li class="nav-item dropdown dmenu" *ngIf="loggedInRoleId == 1 || loggedInRoleId == 2" routerLinkActive="active">
              <a class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown"><img src="assets/images/home/create_question.png" alt="" class="mr-2">
                <b>QUESTIONS</b>
              </a>
              <div class="dropdown-menu sm-menu">              
                <a routerLink="/create-questions" class="dropdown-item" id="deskContent" *ngIf="loggedInRoleId == 1 || loggedInRoleId == 2" routerLinkActive="active"><b>CREATE QUESTIONS</b></a>
                <a routerLink="/kpi-questions" class="dropdown-item" *ngIf="loggedInRoleId == 1 || loggedInRoleId==2" routerLinkActive="active"><b>VIEW QUESTIONS</b></a>
              </div>
            </li>
            <li class="nav-item"  *ngIf="loggedInRoleId == 1  || loggedInRoleId == 2">
              <a class="nav-link" routerLink="/create-form" routerLinkActive="active"><img src="assets/images/home/activate_data_collection.png" alt="" class="mr-2"><b>CREATE DATA COLLECTION FORM </b><span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item" *ngIf="(loggedInRoleId != 5)">
              <a class="nav-link" routerLink="/forms-list" routerLinkActive="active"><img src="assets/images/home/export (2).png" class="mr-2"/><b>FORMS LIST</b></a>
            </li> 
            <!-- <li class="nav-item" *ngIf="loggedInRoleId == 1">
              <a class="nav-link" routerLink="quiz-test" routerLinkActive="active"><b>quiz</b></a>
            </li>  -->
          </ul> 
          <div class="social-part mr-1">            
            <a href="#" class="nav-item nav-link "routerLink="login"  *ngIf="!loggedInData" routerLinkActive="active">Login</a>
            <a href="#" class="nav-item nav-link " (click)="logout()" *ngIf="loggedInData" style="color: rgb(94, 93, 93);" 
            routerLinkActive="active">
              <img src="assets/images/home/Sign_out.png" class="mr-2"/>
              <b>SIGN OUT</b>
            </a>
          </div>
          <div class="social-part">            
            <a *ngIf="loggedInRoleId == '0'" title="help" href="https://docs.google.com/document/d/1uP2mhCBGyADbL1NCAdjGaJv5dwAP9VIw/edit?usp=sharing&ouid=113688868882907807733&rtpof=true&sd=true" class="btn" target="_blank"><i class="fa fa-info-circle fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '0'" title="help" href="https://docs.google.com/document/d/1_IOHlQoJOQx8V6GOi-JFaJy4tlLKs0UU/edit" class="btn" target="_blank"><i class="fa fa-info-circle fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '0'" title="help" href="https://docs.google.com/document/d/1Tg1soAW58yvS-L8mTsLXkJUZH3wsh3Qc/edit?usp=sharing&ouid=113688868882907807733&rtpof=true&sd=true" class="btn" target="_blank"><i class="fa fa-info-circle fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '0'" title="help" href="https://docs.google.com/document/d/1LfdKs_i6XJg4OiPbjW-8KmC_UJCj9BYe/edit?usp=sharing&ouid=113688868882907807733&rtpof=true&sd=true" class="btn" target="_blank"><i class="fa fa-info-circle fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '1'" routerLink="/password-reset" title="password reset" class="btn"><i class="fa fa-lock fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '4'" routerLink="/password-reset" title="password reset" class="btn"><i class="fa fa-lock fa-1x"></i></a> 
            <a *ngIf="loggedInRoleId == '2'" routerLink="/password-reset" title="password reset" class="btn"><i class="fa fa-lock fa-1x"></i></a> 
          </div>
       
   
        </div>
      </nav>
</div>

<script>
    $("dropdown-item").click(function() {
        $("dropdown-item").not($(this)).each(function() {
            $(this).close();
        });
    });
    function close() {
        console.log('close')
    }
</script>





  