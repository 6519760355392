import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { first } from 'rxjs/operators';
// import swal from 'sweetalert2';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { ApiService } from '../../allServices/api.service';
import { UriList } from '../../uri.list';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {

  kpis:any;
  stakeholders:any;
  buttonDisabled:boolean=false;
  dropdownList = [];
  projectDropdownList = [];
  outcomeKpisObj = [];
  projectOutcome = [];
  kpisFormsData:any = [];
  regionDropdownList:any=[];
  kpiQuestionData:any=[];
  loggedInData:any;
  questionData:any;
  dropdownSettings:IDropdownSettings={};
  projectDropdownSettings:IDropdownSettings={};
  kpiQuestionIdsData=[];
  public loggedInRoleId:any;
  public stakeholdersByIdData:any;

  constructor(private auth:ApiService) {}

  ngOnInit() {
    this.getStakeholders();
    this.getRegion();
    this.dropdownList = [];
    this.projectDropdownList=[];
    this.dropdownSettings = {
      idField: 'id',
      textField: 'title',
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
    };
    this.projectDropdownSettings = {
      idField: 'id',
      textField: 'name',
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
    };
    this.loggedInData = localStorage.getItem('user_data');
    this.collectionForm.user_id = (this.loggedInData) ? JSON.parse(this.loggedInData).user_id : 0;
    this.loggedInRoleId = ((JSON.parse(this.loggedInData))? JSON.parse(this.loggedInData).role_id : 0);
    this.collectionForm.region_id = (this.loggedInRoleId == 2) ? JSON.parse(this.loggedInData).region_id : '';
  }
  success = false;
  stakeholderSuccess: boolean = false;
  collectionForm:any={
    user_id: '',
    region_id:'',
    project_id: '',
    kpi_ids:[],
    stakeholder_id:'',
    project_form_id:'',
    questions:'',
    target:'',
    from_date:'',
    to_date:'',
    project_code:'',
  }
  ngDoCheck(){
    this.collectionForm.kpi_ids = this.outcomeKpisObj.map(s=>s.id);
    // this.collectionForm.project_id = this.projectOutcome.map(s=>s.id);
    // this.collectionForm.project_form_id = this.randomstring(10);
    // this.collectionForm.questions = this.uniq(this.kpiQuestionIdsData);
    // console.log(this.collectionForm.project_id)
  }
  ngAfterViewInit(): void{
    this.loadAllKpis();
    this.getProject();
  }
  getProjectName(project_id)
  {
    const date = new Date();
    let time = date.getTime();
    this.projectDropdownList.map((result)=>{
      if(result.id ==  project_id){
        this.collectionForm.project_form_id = date.toISOString().slice(0, 10) +"-"+time+"-"+ result.name;
      }
    });
    this.projectDropdownList.map((result)=>{
      if(result.id ==  project_id){
        this.collectionForm.project_code = result.project_code;
      }
    });
    this.loadAllKpis();
    // console.info(this.collectionForm.project_code)
  }
  private getProject()
  {
    let params = new HttpParams().set("user_id",this.collectionForm.user_id)
    // console.log(params)
    return this.auth.getByParams(`${UriList.GETPROJECT}`,{params:params}).pipe(first()).subscribe(r => {
      this.projectDropdownList=r['data'];
    });
  }
  private getRegion()
  {
    let params = new HttpParams().set("user_id",this.collectionForm.user_id)
    return this.auth.getByParams(`${UriList.GETREGION}`,{params:params}).pipe(first()).subscribe(region => {
        this.regionDropdownList=region['data'];
    });
  }
  private getStakeholders()
  {
    return this.auth.get(`${UriList.GETSTAKEHOLDER}`).pipe(first()).subscribe(res => {
      // console.info(res['data'])
      this.stakeholders=res['data'];
    });
  }
  public getStakeholdersByIdData(stakeholder_id:number): any{
    return this.auth.getById(stakeholder_id, `${UriList.GETSTAKEHOLDERFORM}`).pipe(first()).subscribe(Stakeholder => {
      console.info(Stakeholder['data'])
      this.stakeholdersByIdData=Stakeholder['data'];
      if(Object.keys(Stakeholder['data']).length > 0){
        this.stakeholderSuccess=true;
      }else{
        this.stakeholderSuccess=false;
      }
    },err=>{
      console.log(err)
    });
  }
  public loadAllKpis() {
    // let params = {};
    // if(this.loggedInRoleId == '2'){
    //   params = new HttpParams().set("user_id",this.collectionForm.user_id);
    // }else{
     let params = new HttpParams().set("project_id",this.collectionForm.project_code);
    // }
    this.auth.getByParams(`${UriList.GETKPIS}`,{params:params}).pipe(first()).subscribe(kpis => {
      this.dropdownList=kpis['data'];
    });
  }
  async getArrayofKpiFormsData()
  {
    this.auth.create(this.collectionForm,`${UriList.GETARRAOFKPIFORMS}`).pipe(first()).subscribe(formsData => {
      let kpi_data = this.arrayColumn(formsData['data'], 'kpi_data');
      this.kpiQuestionData = this.arrayColumn(kpi_data,'question_data');
      let result = this.kpiQuestionData.reduce((r, a) => r.concat(a), []);
      this.collectionForm.questions = this.uniq(this.arrayColumn(result,'kpi_question_id'));
      // console.log(this.arrayColumn(result,'kpi_question_id'))
      // for (let i = 0; i < result.length; i++) {
      //   // console.log(result[i])
      //   // this.kpiQuestionIdsData = result[i].kpi_question_id;
      //   this.kpiQuestionIdsData = this.kpiQuestionIdsData.concat(result[i].kpi_question_id);
      // }
      this.kpisFormsData = formsData['data'];
      if(Object.keys(formsData['data']).length > 0){
        this.success=true;
      }else{
        this.success=false;
      }
    });
  }
  uniq(val) {
    return val.sort().filter(function(item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  private arrayColumn(array, columnName) {
    return array.map(function(value,index) {
        return value[columnName];
    })
  }
  toggleValue(item){
    item.selected = !item.selected;
  }
  createForm(){
    // console.log(this.collectionForm.questions)
    // return 0
    this.auth.create(this.collectionForm,`${UriList.CREATECOLLECTIONFORM}`).pipe(first()).subscribe(formsData => {
      if(Object.keys(formsData['data']).length > 0){
        this.collectionForm={};
        new swal('Success','You have created sucessfully','success');
        this.success=true;
      }else{
        this.success=false;
      }
    },error=>{
      // console.log(error.error.errors[0])
      new swal('Error',error.error.errors[0],'error');
    });
  }
  getRegionId(region_id){
    let params = new HttpParams().set("region_id",region_id)
    return this.auth.getByParams(`${UriList.GETPROJECT}`,{params:params}).pipe(first()).subscribe(r => {
        this.projectDropdownList=r['data'];
    });
  }

private randomstring(L) {
  var s = '';
  var randomchar = ()=> {
    var n = Math.floor(Math.random() * 62);
    if (n < 10) return n; //1-10
    if (n < 36) return String.fromCharCode(n + 55); //A-Z
    return String.fromCharCode(n + 61); //a-z
  }
  while (s.length < L) s += randomchar();
  return s;
}


}
