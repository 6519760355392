<div class="container" id="excel-table">
  <div class="card-rounded mt-5 mb-5">
    <div class="card-header">
      <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
      <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
      <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
      <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
      <h4 class="mt-1 mb-1" style="text-align: center;">FORM BASED QUESTIONS </h4>
      <h6>({{project_form_id}}) </h6>
    </div>
    <div class="container mt-4">
      <div class="col-12 mb-4">
        <a href="#demo" class="btn btn-warning" data-toggle="collapse"><h5># Proflie Questions</h5></a>
          <div id="demo" class="collapse col-12">
            <div *ngFor="let stakeholderItem of stakeholderData; let i = index" class="form-group" >
              <div *ngIf="stakeholderItem.type=='text'">
              <b>{{i +1}}. </b> <label> {{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              <div *ngIf="stakeholderItem.type=='group_text'">
                <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              </div>
              <div *ngIf="stakeholderItem.type=='email'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='phone'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='number'">
                <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
                </div>
              <div *ngIf="stakeholderItem.type=='date'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='datetime-local'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='textarea'">
                <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='paragraph'">
                <p class="form-text">{{stakeholderItem.placeholder}}</p>
              </div>
              <div *ngIf="stakeholderItem.type=='autocomplete'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
                <!-- <small class="form-text text-muted">{{stakeholderItem.description}}</small> -->
              </div>
              <div *ngIf="stakeholderItem.type=='checkbox'">
              <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
              <div *ngIf="stakeholderItem.type=='radio'">
                <b>{{i +1}}. </b> <label>{{stakeholderItem.label}}</label> <label *ngIf="stakeholderItem.required" class="text-danger">*</label>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12">
          <div class="mb-5" *ngIf="success" id="viewAnswer">
              <form class="ftheme" >  
                <h4 class="viewAnswer-heading" ># KPI Questions</h4> <br>
                <div *ngFor="let item of projectsData; let i = index" class="form-group" >
                  <div *ngIf="item.type=='text'">
                    <b>{{i + 1}}. </b>  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label> 
                  </div>
                    <div *ngIf="item.type=='group_text'">
                      <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                    </div>
                  <div *ngIf="item.type=='email'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                  </div>
                  <div *ngIf="item.type=='phone'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                    </div>
                  <div *ngIf="item.type=='number'">
                      <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                    </div>
                  <div *ngIf="item.type=='date'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                  </div>
                  <div *ngIf="item.type=='datetime-local'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                  </div>
                  <div *ngIf="item.type=='textarea'">
                      <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                  </div>
                  <div *ngIf="item.type=='paragraph'">
                    <p class="form-text">{{item.placeholder}}</p>
                  </div>
                  <div *ngIf="item.type=='autocomplete'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                    <select class="form-control" id="{{item.name}}" >
                    <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option>  
                  </select>
                    <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                  </div>
                  <div *ngIf="item.type=='checkbox'">
                    <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                    <div *ngFor="let v of item.values" class="inline-form-group">
                    <label class="cust-check-bx">
                      <input type="checkbox" [value]="v.value" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  </div>
                  <div *ngIf="item.type=='radio'">
                      <b>{{i + 1}}. </b>   <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
                      <div *ngFor="let v of item.values" class="inline-form-group">
                      <label class="cust-check-bx">
                        <input type="radio" [value]="v.value" name="{{v.label}}" > {{v.label}}
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="form_status == 'null' && loggedInRoleId == '4'" class="col-12 mb-5">
              <div class="">
                <button class="btn btn-primary mr-2" (click)="approved(1)" *ngIf="!showTextBox"><i class="fa fa-check" aria-hidden="true"></i></button>
                <button class="btn btn-danger" (click)="showRejected()" *ngIf="!showTextBox"><i class="fa fa-times" aria-hidden="true"></i></button>
              </div>
              <div class="mt-2 mb-2 col-12" *ngIf="showTextBox">
                <form>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Write Remark</label>
                  <textarea class="form-control" name="remarks" rows="3" [(ngModel)]="remarks"></textarea>
                  {{remarks}}
                </div>
                
                  <div class="form-group">
                    <button class="btn btn-danger" (click)="rejected(2)">Rejected</button>
                </div>
                </form>
              </div>
            </div>
      </div>
    </div>
  </div>
</div>

