import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DndModule } from 'ngx-drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KpiQuestionsComponent } from './pages/kpi-questions/kpi-questions.component';
import { CreateKpiComponent } from './pages/create-kpi/create-kpi.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { CreateQuestionsComponent } from './pages/create-questions/create-questions.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { AdminAuthGuard } from './authGuard/admin-auth.guard';
import { LoginComponent } from './authentication/login/login.component';
import { HomeComponent } from './home/home.component';
import { CreateFormComponent } from './pages/create-form/create-form.component';
import { TokenInterceptor } from './authentication/token.interceptor';
import { DataCollectorComponent } from './pages/data-collector/data-collector.component';
import { DataCollectionComponent } from './pages/data-collection/data-collection.component';
import { GetAnswerComponent } from './pages/get-answer/get-answer.component';
import { MaterialModule } from './material/material.module';
import { QuizComponent } from './pages/quiz/quiz.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter'; 
import { ToastrModule } from 'ngx-toastr';
import { KpiListComponent } from './pages/kpi-list/kpi-list.component';
import { ProjectManagersListComponent } from './pages/project-managers-list/project-managers-list.component';
import { ExportDataComponent } from './pages/export-data/export-data.component';
import { NgxUiLoaderModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION, } from "ngx-ui-loader";
import { GetDataCollectionComponent } from './pages/get-data-collection/get-data-collection.component';
import { FormBasedQuestionsComponent } from './pages/form-based-questions/form-based-questions.component';
import { FormsListComponent } from './pages/forms-list/forms-list.component';
import { DataCollectorDashboardComponent } from './pages/data-collector-dashboard/data-collector-dashboard.component';
import { PasswordResetComponent } from '../app/authentication/password-reset/password-reset.component';
import { ViewUsersComponent } from './users/view-users/view-users.component';
import { Store } from '@ngrx/store';
import { userReducer } from './store/reducers/users.reducers';
import { appReducer } from './store/state';
import { StoreModule } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';
// import { EntityDataModule } from '@ngrx/data';
import { UserAddComponent } from './users/user-add/user-add.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#FFC416",
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 1,
  // bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 1, // progress bar thickness
  pbColor:'#FFC416',
};

@NgModule({
  declarations: [
    AppComponent,
    KpiQuestionsComponent,
    CreateKpiComponent,
    HeaderComponent,
    FooterComponent,
    CreateQuestionsComponent,
    CreateProjectComponent,
    LoginComponent,
    HomeComponent,
    CreateFormComponent,
    DataCollectorComponent,
    DataCollectionComponent,
    GetAnswerComponent,
    QuizComponent,
    KpiListComponent,
    ProjectManagersListComponent,
    ExportDataComponent,
    GetDataCollectionComponent,
    FormBasedQuestionsComponent,
    FormsListComponent,
    DataCollectorDashboardComponent,
    PasswordResetComponent,
    ViewUsersComponent,
    UserAddComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    DndModule,
    HttpClientModule,
    MaterialModule,
    NgxPaginationModule,
    Ng2SearchPipeModule ,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 500,
      progressBar: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ 
        loaderId:"master",
        showForeground: true,
        delay: 5,
      }),
    // EffectsModule.forRoot([]),
    // EntityDataModule.forRoot({}),
    StoreModule.forRoot( appReducer),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AdminAuthGuard,
    Store
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
