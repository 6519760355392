import { Login } from "../model/login.model";
import * as LoginActions from '../actions/login.action';
import { UriList } from "src/app/uri.list";
import { catchError, map } from "rxjs/operators";
import { initialState } from "../state/login.state"
import { createReducer } from "@ngrx/store";


// export interface LoginState {
//     readonly loginStore: Login[];
// }

// const initialState: Login = {
//     email: '',
//     password: '',
//     role_id: false,
// }

const _loginReducer = createReducer(initialState);

export function loginReducer(state, action) {
    return _loginReducer(state,action);
}
// export function loginReducer(state: Login[] = [initialState], action: LoginActions.Actions) {
//     switch(action.type) {
//         case LoginActions.LOGIN:
//             return this.http.post(`${UriList.LOGIN}`,action.payload).pipe(
//                 map((response: any) => {console.log(response)}),
//                 error => {console.log(error)});
//         case LoginActions.LOGOUT:
//             console.log("Logout",action.payload)
//             return 0;
//         default:
//             return state;
//     }
// }
