import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../../store/reducers/users.reducers';
import * as UserActions from '../../store/actions/users.actions';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  constructor(private store: Store<UserState>) { }

  addToStore(nameL: string, genderL: string) {
    this.store.dispatch(new UserActions.AddUser({
      name: nameL,
      gender: genderL
    }))
  }

  ngOnInit() {
  }

}
