export const UriList = {
    DASHBOARD: "dashboard",
    LOGIN: "login",
    GETSTAKEHOLDER:"get-stakeholder",
    GETPROJECT:"get-project",
    PROJECT:"project",
    CREATECOLLECTIONFORM:"create-collection-form",
    GETKPIS:"get-kpis",
    GETARRAOFKPIFORMS:"get-arrayof-kpi-forms",
    GETRELATEDPUTCOMEINDICATOR:"get-related-outcome-indicator",
    QUESTION:"question",
    GETQUESTIONSTOREANSWER:"get-question-store-answer",
    DATACOLLECTORPROJECT:"data-collector-project",
    GETDATACOLLECTOR:"get-data-collector",
    GETPROJECTBYCOLLECTIONFORM:"get-projects-by-collection-form",
    STORESHAREDPROJECT:"store-shared-project",
    REGISTER:"register",
    URERDELETE:"user_delete",
    GETCOLLECTIONFORM:"get-collection-form",
    EXPORTDATACOLLECTION:"export-data-collection",
    EXPORTROWDATACOLLECTION:"export-row-data-collection",
    GETANSWERWITHPROFILE:"get-answer-with-profile",
    GETEXPORTWITHANSWER:"getExportWithAnswer",
    GETKPIFORM:"get-kpi-form",
    GETSTAKEHOLDERFORM:"get-stakeholder-form",
    GETPROJECTMAGAGERS:"get-project-managers",
    GETCOMMODITY:"get-commodity",
    CREATERFARMERPROFILE:"create-farmer-profile",
    GETREGION:"get-region",
    GETSTOREPROFILE:"get-store-profile",
    GETFORMBYPROJECTID:"get-formby-project-id",
    STOREFORMSTATUS:"store-form-status",
    GETKPISCREATEDBYREC:"get-kpis-created-by-rec",
};