import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API_URL = environment.hostUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http: HttpClient) { }

  loggedInUser(uri:any,user){
    return this._http.post<any>(`${API_URL}/${uri}`, user);
  }

  allUsers(){
    return this._http.get<any>(`${API_URL}`);
  }

  loggedIn(){
    return !!localStorage.getItem('token');
  }

  getToken(){
    return localStorage.getItem('token');
  }

}
