<div class="cotainer">
  <div *ngIf="model && !report" class="white-bg">
  <div class="row mt-4"> 
  <div class="col-md-2 ml-5">
  <div class="fullSection ">
  <div class="card-header" >
  <div class="img5"><img src="assets/images/home/small_right_top.png" alt=""></div>
  <div class="img6"><img src="assets/images/home/small_right_top.png" alt=""></div>
  <h6>DRAG FIELDS</h6>
  </div>
  <!--a draggable element-->
  <div class="fields" *ngFor="let item of fieldModels">
  <div class="field pointer shadow-bg" [dndDraggable]="item"
  [dndEffectAllowed]="'copy'" 
  (dndEnd)="onDragEnd($event)">
  <i [ngClass]="item.icon" class="fa pull-left"></i>
  <h5>{{item.label}}</h5>
  </div>
  </div>
  </div>
  </div>
  <!-- <div class="col-9">
  <div class="card-header">
  <h5 class="mb-0" style="text-align: center;">Create Question</h5>
  </div>
  </div> -->
  <div class="card-rounded mt-0 ml-5 mb-3">
  <div class="card-header">
  <div class="img1"><img src="assets/images/home/right_top.png" alt=""></div>
  <div class="img2"><img src="assets/images/home/left_bottom.png" alt=""></div>
  <div class="img3"><img src="assets/images/login/corner_image1.png" alt=""></div>
  <div class="img4"><img src="assets/images/login/corner_image2.png" alt=""></div>
  <h4 class="mt-1 mb-1" style="text-align: center;">CREATE QUESTION </h4>
  </div>
  <div class="col-md-12">
  <div class="custom-control custom-switch pull-right" *ngIf="loggedInRoleId != 2">
  <input type="checkbox" class="custom-control-input" id="switch" [(ngModel)]="kpiOrStackholderView" (change)="kpiOrStakerholderAction()">
  <label class="custom-control-label" for="switch"><span *ngIf="kpiOrStackholderView == 1"></span><span *ngIf="kpiOrStackholderView == 0"></span></label>
  <label>Switch</label>
  </div>
  <div class="form-group"
  style="margin-bottom: 30px !important; padding: 0px 0px 5px 0px;"
  [style.backgroundColor]="model.theme.bgColor"
  [style.color]="model.theme.textColor">
  <h5 class="mt-5" *ngIf="kpiOrStackholderView == 1">
  <select class="form-control" [(ngModel)]="model.kpi_id" style="border-radius: 10px;
  box-shadow: 0px 4px 0px #FFC416;" >
  <option value="0" selected>Select KPI's</option>
  <option *ngFor="let x of kpis" value="{{x.id}}">{{x.title}}</option>
  </select> 
  </h5>
  <h5 class="mt-5" *ngIf="kpiOrStackholderView == 0">
  <select class="form-control" [(ngModel)]="model.stakeholder_id" style="border-radius: 10px;
  box-shadow: 0px 4px 0px #FFC416;" >
  <option value="0" selected>Select stackholder</option>
  <option *ngFor="let kld of kpiListData" value="{{kld.id}}">{{kld.name}}</option>
  </select> 
  </h5>
  </div>
 
  <!--a dropzone-->
  <!--to allow dropping content that is not [dndDraggable] set dndAllowExternal to true-->
  <div class="sectionViews">
  <section dndDropzone
  (dndDragover)="onDragover($event)"
  (dndDrop)="onDrop($event,model.attributes)" class="dndDropArea"
  [ngClass]="{'empty':model.attributes.length == 0}"
  [style.backgroundColor]="model.theme.bgColor"
  [style.color]="model.theme.textColor"> 
  <di *ngIf="((model.attributes).length == 0)">
  <h3>Drag from left and drop here</h3>
  </di> 
  <div class="field" *ngFor="let item of model.attributes;let i= index;" 
  (dndStart)="onDragStart($event)"
  (dndCanceled)="onDragCanceled($event)"
  (dndMoved)="onDragged(item, model.attributes, 'move')"
  [dndEffectAllowed]="'all'" 
  [dndDraggable]="item">
  <div class="row form-group">
  <div class="col-1"><i class="fa fa-ellipsis-v dndHandle" data-title="Hold & Drag"></i></div>
  <div class="col-7">
  <div *ngIf="item.type=='text'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="text" #txtfield value={{bold}} class="form-control fontchange" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='group_text'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='email'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="email" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='phone'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="text" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='number'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}" max="{{item.max}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='date'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="date" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='datetime-local'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input type="datetime-local" class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </div>
  <div *ngIf="item.type=='textarea'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}" >
  </textarea>
  </div>
  <div *ngIf="item.type=='paragraph'">
  <p class="form-text">{{item.placeholder}}</p>
  </div>
  <div *ngIf="item.type=='autocomplete'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <select class="form-control" id="{{item.name}}" >
  <option *ngFor="let v of item.values" [value]="v.value">{{v.label}}</option> 
  </select>
  </div>
  <div *ngIf="item.type=='checkbox'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <div *ngFor="let v of item.values" class="inline-form-group">
  <label class="cust-check-bx">
  <input type="checkbox" [value]="v.value" name="{{item.name}}" (click)="toggleValue(v)"> {{v.label}}
  <span class="checkmark"></span>
  </label>
  </div>
  </div>
  <div *ngIf="item.type=='radio'">
  <label>{{item.label}}</label> <label *ngIf="item.required" class="text-danger">*</label>
  <div *ngFor="let v of item.values" class="inline-form-group">
  <label class="cust-check-bx">
  <input type="radio" [value]="v.value" name="{{item.name}}" > {{v.label}}
  <span class="checkmark"></span>
  </label>
  </div>
  </div>
  </div>
  <div class="col-4">
  <i *ngIf="item.toggle" (click)="item.toggle=false" class="fa fa-chevron-up pull-right"></i>
  <i *ngIf="!item.toggle" (click)="item.toggle=true" class="fa fa-chevron-down pull-right"></i>
  <i (click)="removeField(i,item.jump_reference)" class="fa fa-trash pull-right"></i>
  </div>
  </div>
  <div *ngIf="item.toggle" class="toggle-Wrapper">
  <div class="col-12 row">
  <div class="form-group col-2">
  <label>Required</label>
  <i *ngIf="item.required" (click)="item.required=false" class="fa fa-toggle-on red"></i>
  <i *ngIf="!item.required" (click)="item.required=true" class="fa fa-toggle-off"></i>
  </div>
  <div class="form-group col-2" *ngIf="item.type=='radio'" id="skipQuestion">
  <label>Jump</label>
  <i *ngIf="item.is_jump" (click)="item.is_jump=false" class="fa fa-toggle-on red"></i>
  <i *ngIf="!item.is_jump" (click)="item.is_jump=true" class="fa fa-toggle-off"></i>  
  </div>
  <div class="col-3" *ngIf="item.type=='group_text'" id="groupQuestion">
  <label>Group Question</label>
  <i *ngIf="item.is_group_onOff" (click)="item.is_group_onOff=false" class="fa fa-toggle-on red"></i>
  <i *ngIf="!item.is_group_onOff" (click)="item.is_group_onOff=true" class="fa fa-toggle-off"></i>  
 
  </div>
  </div>
  <div *ngIf="item.is_group_onOff" class="form-group" id="groupQuestion">
  <label>Group the question?</label>
  <select class="form-control" [(ngModel)]="item.group_reference" (change)="selectedGroupQuestion($event.target.value)" >
  <option *ngFor="let group of groupQuestion let groupIndex = index" value="{{group.name}}" [disabled]="i-1 > groupIndex || i <= groupIndex">{{group.label}}</option>
  </select>
  </div>
  <div class="form-group">
  <label>Label</label>
  <input class="form-control" type="text" [(ngModel)]="item.label" />
  </div>
  <div class="form-group" hidden>
  <label>Name</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <input class="form-control" type="text" [(ngModel)]="item.name" />
  </div>
  <!-- <div class="form-group">
  <label>Placeholder</label>
  <input class="form-control" type="text" [(ngModel)]="item.placeholder" />
  </div> -->
  <div class="form-group">
  <label>Regex</label>
  <input class="form-control" type="text" [(ngModel)]="item.regex" />
  </div>
  <div *ngIf="item.is_jump" class="form-group" id="skipQuestion">
  <label>When to skip?</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <select class="form-control" [(ngModel)]="item.jump_value" *ngIf="item.type=='radio'">
  <option value="select options" selected>Select option</option>
  <option *ngFor="let sqo of item.values let i = index" value="{{sqo.label}}">{{sqo.label}}</option>
  </select>
  </div>
  <div *ngIf="item.is_jump" class="form-group" id="skipQuestion">
  <label>Where to skip?</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <select class="form-control" [(ngModel)]="item.jump_reference" (change)="jumpReference($event.target.value)">
  <option value="select options" selected>Select option</option>
  <option *ngFor="let sq of skipQuestion let skipi = index" value="{{sq.name}}" [disabled]="i+1 > skipi">{{sq.label}}</option>
  </select>
  </div>
  <div *ngIf="item.regex" class="form-group">
  <label>Error text</label>
  <input class="form-control" type="text" [(ngModel)]="item.errorText" />
  </div>
  
  <!-- number -->
  <div *ngIf="item.type=='number'" class="row">
  <div class="col-6">
  <div class="form-group">
  <label>Min</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input class="form-control" type="text" [(ngModel)]="item.min" />
  </div>
  </div>
  <div class="col-6">
  <div class="form-group">
  <label>Max</label>
  <i (click)="textBold(item.name)" class="fa fa-bold"></i
  ><i
  (click)="textItalic(item.name)"
  class="fa fa-italic"
  ></i>
  <i 
  (click)="textUnderline(item.name)"
  class="fa fa-underline" aria-hidden="true"
  ></i>
  <!-- <i 
  (click)="textLink(item.name)"
  class="fa fa-3x fa-link
  "></i> -->
  <!-- <i (click)="textshadow(item.name)" class="fa fa-20x fa-circle"></i> -->
  <input class="form-control" type="text" [(ngModel)]="item.max" />
  </div>
  </div>
  </div>
 
  <!-- radio || checkbox || autocomplete -->
  <div *ngIf="item.type=='radio'|| item.type=='checkbox' || item.type=='autocomplete'" >
  <div class="row">
  <div class="col-5">
  <div class="form-group">
  <label>Label</label>
  </div>
  </div>
  <div class="col-5">
  <div class="form-group">
  <label>Value</label>
  </div>
  </div>
  <div class="col-2">
  <div class="form-group">
  <label>Action</label>
  </div>
  </div>
  </div>
  <div *ngFor="let i of item.values; let valueIndex=index;" class="row">
  <div class="col-5">
  <div class="form-group">
  <input class="form-control" type="text" [(ngModel)]="i.label" />
  </div>
  </div>
  <div class="col-5">
  <div class="form-group">
  <input class="form-control" type="text" [(ngModel)]="i.value" />
  </div>
  </div>
  <div class="col-2">
  <div class="form-group">
  <label (click)="item.values.splice(valueIndex,1)">remove</label>
  </div>
  </div>
  </div>
  <div class="row">
  <div class="col-5">
  <div class="form-group">
  <input class="form-control" type="text" [(ngModel)]="value.label" />
  </div>
  </div>
  <div class="col-5">
  <div class="form-group">
  <input class="form-control" type="text" [(ngModel)]="value.value" />
  </div>
  </div>
  <div class="col-2">
  <div class="form-group">
  <label (click)="addValue(item.values)">Add</label>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <!--optional placeholder element for dropzone-->
  <!--will be removed from DOM on init-->
  <div class="dndPlaceholder" dndPlaceholderRef>
  </div>
  </section>
  </div>
  <div class="ml-4 mt-4 mr-5" *ngIf="model.kpi_id != 0 || model.stakeholder_id != 0">
  <button (click)="updateForm()" class="btn btn-outline-primary pull-right" style="margin-left: 3px;">Add Questions</button>
  <button type="reset" (click)="reset()" class="btn btn-danger pull-right ml-3">Reset Questions</button>
  </div>
  </div>
  </div>
  </div>
 </div>
 </div>